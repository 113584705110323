.avatar {
    &.round {
        border-radius: 50%;
        overflow: hidden;
    }
    img {
        width: 100%;
        min-width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.avt-40 {
    width: 40px;
    min-width: 40px;
    height: 40px;
}
.avt-56 {
    width: 56px;
    min-width: 56px;
    height: 56px;
}
.avt-60 {
    width: 60px;
    min-width: 60px;
    height: 60px;
}
.avt-100 {
    width: 100px;
    min-width: 100px;
    height: 100px;
}
