.collection-item {
  .collection-inner {
    position: relative;
  }
  .collection-image {
    width: 100%;
    display: block;
    border-radius: 10px;
    overflow: hidden;
  }
  .collection-content {
    position: absolute;
    z-index: 3;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
  }
  .collection-title {
    line-height: 40px;
    height: 40px;
    font-size: 16px;
    padding: 0px 14px;
    background-color: var(--white);
    border-radius: 3px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .icon {
      color: var(--white);
      margin: 0;
    }
    &:hover {
      background-color: var(--main);
      color: var(--white);
    }
  }
  &.style-left {
    .collection-content {
      left: 0;
      transform: unset;
      .collection-title {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}
.collection-item-v2 {
  .collection-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .collection-image {
    width: 100%;
    height: 100%;
  }
  .collection-content {
    position: absolute;
    inset: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .heading {
      margin-bottom: 7px;
      font-size: 18px;
      line-height: 21.6px;
    }
    .subheading {
      font-size: 16px;
      line-height: 19.2px;
      margin-bottom: 7px;
    }
  }
  &.type-small {
    .collection-content {
      .heading {
        font-size: 18px;
        margin-bottom: 0;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

.collection-item-v3 {
  .collection-image {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    .box-icon {
      position: absolute;
      border-radius: 50%;
      color: var(--main);
      font-size: 10px;
      bottom: 15px;
      right: 15px;
      background-color: var(--white);
      width: 35px;
      height: 35px;
      &:hover {
        background-color: var(--main);
        color: var(--white);
      }
    }
  }
  .collection-content {
    margin-top: 15px;
    .title {
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 5px;
    }
    .count {
      font-size: 14px;
      line-height: 22.4px;
    }
  }
}
.collection-item-v4 {
  .collection-inner {
    position: relative;
  }
  .collection-image {
    display: block;
  }
  .collection-content {
    text-align: center;
    bottom: 7%;
    position: absolute;
    left: 7%;
    right: 7%;
    .heading {
      font-size: 18px;
      line-height: 21.6px;
      text-transform: capitalize;
    }
    .subheading {
      font-size: 14px;
      line-height: 22.4px;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 6px;
    }
    .tf-btn {
      margin-top: 14px;
    }
  }
  &.style-2 {
    .collection-content {
      bottom: unset;
      top: 50%;
      transform: translateY(-50%);
      .subtext {
        margin-top: 9px;
      }
      .heading {
        font-size: 22px;
        line-height: 26.4px;
      }
    }
  }
  &.style-3 {
    .collection-content {
      text-align: left;
    }
  }
  &.st-lg {
    .collection-content {
      .heading {
        font-size: 28px;
        line-height: 33.6px;
        margin-bottom: 10px;
      }
      .subtext {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
}
.collection-item-v5 {
  .collection-inner {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .collection-image {
    width: 100%;
    height: 100%;
    display: block;
  }
  .collection-content {
    position: absolute;
    left: 15px;
    bottom: 15px;
    .collection-title {
      padding: 10px 12px;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      background-color: var(--white);
      border-radius: 3px;
      gap: 5px;
      .icon {
        font-size: 12px;
        font-weight: 500;
      }
      &:hover {
        background-color: var(--main);
        color: var(--white);
      }
    }
  }
}
.collection-item-v6 {
  .collection-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }
  .collection-image {
    width: 100%;
    height: 100%;
    display: block;
  }
  .collection-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary);
    padding: 14px;
    .heading {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 2px;
    }
    .subheading {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
}

.collection-item-circle {
  position: relative;
  .collection-image {
    border-radius: 50%;
    overflow: hidden;
    display: block;
  }
  .collection-content {
    margin-top: 15px;
    .title {
      margin-bottom: 5px;
      font-size: 15px;
      line-height: 18px;
      font-weight: 500;
    }
    .count {
      color: var(--text);
    }
  }
  .sale-off {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    line-height: 19px;
    padding: 0 5px;
    background-color: var(--main);
    border-radius: 999px;
    color: var(--white);
  }
  &.has-bg {
    padding: 15px 0;
    background-color: var(--bg-2);
    border-radius: 20px;
    .collection-image {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    .collection-content {
      margin-top: 19px;
      .title {
        margin-bottom: 0;
      }
    }
  }
  &.has-bg-2 {
    background-color: #f2f2f2;
    border-radius: 3px;
  }
  &.circle-line {
    .collection-image {
      padding: 15px;
      border: 1px solid var(--primary);
    }
    .collection-content {
      margin-top: 20px;
      .title {
        margin: 0;
      }
    }
  }
}

.tf-shopall {
  .tf-shopall-icon {
    width: 99px;
    height: 99px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main);
    font-size: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}

.tf-categories-wrap {
  display: flex;
  overflow-x: auto;
  .tf-categories-container {
    display: flex;
  }
  .collection-item-circle {
    img {
      max-width: 104px;
    }
  }
  .collection-item-circle {
    min-width: 155px;
    .has-saleoff-wrap,
    .collection-image {
      width: 99px;
      height: 99px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .collection-content {
    margin-top: 17px !important;
    a {
      font-size: 14px !important;
      line-height: 17px !important;
    }
  }
}

.slider-collection {
  padding-bottom: 6px;
  .collection-item {
    .collection-title {
      padding: 0 16px;
      line-height: 44px;
      height: 44px;
      font-size: 16px;
      background-color: var(--white);
      color: var(--main);
      @include flex(center, center);
      white-space: nowrap;
      .icon {
        display: none;
        font-size: 10px;
        margin-top: 2px;
      }
      &:hover {
        background-color: var(--main);
        color: var(--white);
      }
    }
  }
}

.collection-title-v2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  border-radius: 3px;
  padding: 0px 30px;
  @include transition3;
  background-color: var(--white);
  &:hover {
    color: var(--white);
    background-color: var(--main);
  }
}

.discovery-new-item {
  padding: 37px;
  height: 100%;
  border: 1px solid var(--main);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 10px;
  gap: 30px;
  a {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--main);
    border-radius: 50%;
    font-size: 10px;
    color: var(--main);
    &:hover {
      background-color: var(--main);
      color: var(--white);
    }
  }
}

.banner-gr-item {
  position: relative;
  .img-style {
    margin-bottom: 30px;
  }
  .content {
    .title {
      font-size: 28px;
      line-height: 34px;
    }
    > a {
      margin-top: 18px;
    }
  }
  &.style-content-absolute {
    .content {
      position: absolute;
      left: 30px;
      right: 30px;
      p {
        color: var(--main);
      }
    }
    .absolute-bot {
      bottom: 50px;
      top: unset;
    }
    .absolute-top {
      top: 50px;
      bottom: unset;
    }
    .absolute-center {
      top: 50%;
      transform: translateY(-50%);
      bottom: unset;
    }
  }
}

.tf-sw-collection {
  .swiper-slide {
    height: auto;
    img {
      height: 100%;
    }
  }
}

.tf-banner-collection {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  .box-content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    .sub {
      line-height: 17px;
    }
    .heading {
      font-size: 42px;
      line-height: 50px;
      margin-top: 5px;
    }
    p {
      font-size: 20px;
      line-height: 32px;
      margin-top: 15px;
      margin-bottom: 26px;
    }
  }
}
.collection-line-upper {
  .collection-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid var(--main);
  }
  .collection-image {
    width: 100%;
    height: 100%;
  }
  .collection-content {
    position: absolute;
    bottom: 10%;
    left: 10%;
    right: 10%;
    .collection-title {
      font-size: 12px;
      line-height: 14.4px;
      text-transform: uppercase;
      padding: 10px 24px;
      font-weight: 700;
    }
    .subheading {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 22.4px;
      text-transform: uppercase;
      font-weight: 700;
    }
    .heading {
      margin-bottom: 11px;
      font-size: 18px;
      line-height: 21.6px;
      text-transform: uppercase;
      font-weight: 800;
    }
  }
}
.collection-item-centered {
  position: relative;
  .img-style {
    width: 100%;
    height: 100%;
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .tf-btn {
      padding: 0px 16px;
      line-height: 44px;
      border: 0;
      span {
        text-wrap: nowrap;
      }
    }
  }
}
.flat-categories-bg {
  background-color: #f8f8f8;
  border-radius: 10px;
  overflow: hidden;
  padding: 17px 15px;
  .collection-item-circle {
    .collection-content {
      margin-top: 20px;
      .title {
        font-size: 16px;
        line-height: 19.2px;
        margin-bottom: 0;
      }
    }
  }

  .nav-sw {
    &.nav-next-slider {
      left: 0;
    }
    &.nav-prev-slider {
      right: 0;
    }
    border: 0;
    top: 44%;
    &:hover {
      color: var(--primary);
      background-color: transparent;
    }
  }
}

.swiper-slide {
  .collection-item-v4,
  .collection-item-v4 .collection-inner,
  .collection-item-v4 .collection-image {
    height: 100%;
  }
}
