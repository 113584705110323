.footer {
  .footer-wrap {
    .footer-body {
      padding-top: 40px;
      padding-bottom: 40px;
      position: relative;
      &::before {
        position: absolute;
        content: "";
        top: 0;
      
        left: 50%;
        background-color: var(--line);
        height: 1px;
        width: 100%;
        max-width: 1440px;
        transform: translateX(-50%);
      }
    }
    .footer-bottom {
      .footer-bottom-wrap {
        padding-top: 21px;
        border-top: 1px solid var(--line);
        padding-bottom: 20px;
        .footer-menu_item {
          color: rgb(84, 84, 84);
        }
      }
    }
  }
  &.has-border-full {
    .footer-body {
      &::before {
        left: 0;
        max-width: 100%;
        transform: unset;
      }
    }
    .footer-newsletter {
      .footer-menu_item {
        line-height: 22.4px;
      }
    }
  }
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-heading {
    margin-bottom: 25px;
    h6 {
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
    }
  }
  .footer-heading-moblie {
    display: none;
  }
  .footer-menu_item {
    line-height: 30px;
  }
  .footer-infor {
    ul {
      margin-bottom: 15px;
      li {
        p {
          // margin-bottom: 4px;
          line-height: 30px;
          a {
            color: rgb(84, 84, 84);
            font-weight: 700;
          }
        }
      }
    }
    > a {
      margin-bottom: 28px;
    }
  }
  ul.footer-menu-list {
    li {
      a {
        &:hover {
          color: var(--primary);
        }
      }
    }
  }
  .footer-newsletter {
    form {
      margin-top: 27px;
      margin-bottom: 40px;
      position: relative;
      input {
        height: 55px;
        padding-right: 143px;
      }
      .button-submit {
        position: absolute;
        top: 7px;
        right: 8px;
        button {
          height: 41px;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
  &.background-black {
    background-color: var(--main);
    .footer-wrap {
      .footer-body {
        &::before {
          display: none;
        }
      }
      .footer-bottom {
        .footer-bottom-wrap {
          border-top: 1px solid rgba(48, 48, 48, 1);
        }
      }
    }
    .footer-infor {
      ul {
        li {
          p {
            color: rgba(161, 161, 161, 1);
            a {
              color: rgba(161, 161, 161, 1);
            }
          }
        }
      }
      > a {
        margin-bottom: 28px;
        color: #a1a1a1;
        &::after {
          background-color: #a1a1a1;
        }
        &:hover {
          color: var(--primary);
          &::after {
            background-color: var(--primary);
          }
        }
      }
    }
    .footer-heading {
      h6 {
        color: var(--white);
      }
    }
    .footer-menu_item {
      color: rgba(161, 161, 161, 1);
    }
    .footer-newsletter form {
      input {
        background-color: rgb(44, 44, 44);
        border: 0;
        color: var(--white);
        &::placeholder {
          color: var(--white);
        }
      }
      .tf-btn {
        border: 0;
      }
    }
  }
  &.bg_green-4 {
    .footer-infor {
      .tf-btn,
      ul li p {
        color: #606a7a;
        a {
          color: #606a7a;
        }
      }
    }
    .footer-menu_item {
      color: #606a7a !important;
    }
    .footer-newsletter form input {
      background-color: rgb(35, 42, 55);
    }
  }
  &.background-green {
    background-color: #214332;
    * {
      border-color: rgba(235, 235, 235, 0.15);
    }
    .footer-wrap {
      .footer-body {
        &::before {
          display: none;
        }
      }
    }
    .footer-infor {
      ul {
        li {
          p {
            color: var(--white) !important;
            a {
              color: var(--white) !important;
            }
          }
        }
      }
      > a {
        margin-bottom: 28px;
      }
    }
    .footer-heading {
      h6 {
        color: var(--white);
      }
    }
    .footer-menu_item {
      color: var(--white) !important;
    }
    .form-newsletter {
      input {
        border-radius: 60px;
      }
      button {
        border-radius: 60px;
        background-color: rgba(23, 46, 34, 1);
      }
    }
  }
  &.background-gray {
    background-color: #f6f6f6;
    .footer-body {
      &::before {
        content: none;
      }
    }
  }
  &.has-border {
    .footer-wrap {
      .footer-body {
        padding-top: 0px;
        padding-bottom: 0px;
        border-bottom: 1px solid var(--line);
      }
      .footer-bottom {
        .footer-bottom-wrap {
          border-top: 0;
        }
      }
    }
    .footer-newsletter {
      border-right: 1px solid var(--line);
      padding-top: 40px;
      padding-bottom: 40px;
      padding-right: 123px;
      width: 40%;
    }
    .footer-col {
      padding-top: 40px;
      padding-bottom: 40px;
      &.footer-col-1 {
        width: 25%;
        padding-top: 40px;
        padding-right: 30px;
        padding-bottom: 60px;
        padding-left: 90px;
      }
      &.footer-col-2 {
        width: 15%;
        padding-top: 40px;
        padding-right: 30px;
        padding-bottom: 30px;
        padding-left: 0px;
      }
      &.footer-col-3 {
        width: 20%;
        padding-top: 40px;
        padding-right: 30px;
        padding-bottom: 30px;
        padding-left: 0px;
      }
    }
  }
  &.has-all-border {
    .footer-wrap {
      .footer-body {
        padding-top: 0px;
        padding-bottom: 0px;
        border-bottom: 1px solid var(--line);
      }
      .footer-bottom {
        .footer-bottom-wrap {
          border-top: 0;
        }
      }
    }
    .footer-newsletter {
      height: 100%;
      padding-top: 40px;
      padding-bottom: 60px;
    }
    .footer-col {
      height: 100%;
      padding-top: 40px;
      padding-bottom: 60px;
      border-right: 1px solid var(--line);
    }
    .tf-social-icon {
      margin-top: 20px;
    }
  }
  &.border-container {
    .footer-body {
      border-bottom: 0 !important;
      &::before {
        width: 1440px;
        left: 50%;
        transform: translateX(-50%);
      }
      &::after {
        position: absolute;
        content: "";
        bottom: 0;
        left: 50%;
        background-color: var(--line);
        height: 1px;
        width: 100%;
        max-width: 1440px;
        transform: translateX(-50%);
      }
    }
  }
}

.tf-cur {
  display: flex;
  gap: 28px;
  @media (max-width: 767px) {
    justify-content: center;
  }
}

.tf-payment {
  display: flex;
  gap: 10px;
}

.tf-toolbar-bottom {
  display: none;
  padding: 0 5px;
  overflow-x: auto;
  overflow-y: hidden;
  position: fixed;
  z-index: 200;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: var(--white);
  box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.08);
  .toolbar-item {
    flex: 1 0 20%;
    position: relative;
    a {
      width: 100%;
      padding-right: 10px;
      padding-left: 10px;
      height: 67px;
      gap: 9px;
      @include flex(center, center);
      flex-direction: column;
      .toolbar-icon {
        width: 18px;
        height: 18px;
        position: relative;
        i {
          font-size: 18px;
          color: var(--main);
        }
        .toolbar-count {
          position: absolute;
          top: -6px;
          right: -12px;
          width: 18px;
          height: 18px;
          @include flex(center, center);
          background-color: var(--primary);
          border-radius: 50%;
          font-size: 10px;
          font-weight: 500;
          line-height: 18px;
          color: var(--white);
        }
      }
      .toolbar-label {
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
      }
    }
    &.active {
      &::before {
        position: absolute;
        content: "";
        left: 0;
        width: 100%;
        height: 3px;
        background-color: var(--primary);
      }
      .toolbar-icon {
        i {
          color: var(--primary);
        }
      }
    }
  }
}
