/* Media Queries
-------------------------------------------------------------- */
// min-width:
@media (min-width: 576px) {
  .card-product {
    .description {
      display: block;
    }
    .countdown-box {
      bottom: 46px;
    }
    .btn-quick-add {
      height: 36px;
      line-height: 36px;
    }
    &.style-4 {
      .size-list {
        bottom: 36px;
      }
    }
    &.style-9 {
      .card-product-info {
        .box-icon {
          width: 36px;
          height: 36px;
        }
      }
    }
  }
  .list-product-btn {
    .box-icon {
      width: 36px;
      height: 36px;
    }
  }
  .modal-newleter {
    .modal-dialog {
      max-width: 625px;
    }
  }
}

@media (min-width: 768px) {
  .page-404-wrap {
    padding: 85px 0 64px;
    .image {
      margin-bottom: 50px;
    }
    .title {
      font-size: 40px;
      line-height: 48px;
    }
    .tf-btn {
      margin-top: 22px;
    }
  }
  .wd-form-address {
    padding: 10px 40px 20px;
  }
  .widget-tab-5 {
    .nav-tab-item {
      a {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  .flat-title-tab {
    gap: 30px;
  }

  .collection-item-v4 {
    &.st-lg {
      .collection-content {
        .heading {
          font-size: 32px;
          line-height: 38.4px;
          margin-bottom: 14px;
        }
      }
    }
  }
  .collection-item-circle {
    &.circle-line {
      .collection-image {
        padding: 17px;
      }
    }
  }
  .flat-categories-bg {
    padding: 48px 30px;
    .collection-item-circle {
      .collection-content {
        .title {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
  .tf-countdown-v3 {
    padding: 8px 14px;
  }
  .tf-form-sneaker {
    .flat-title {
      .title {
        font-size: 28px;
        line-height: 33px;
      }
    }
    .box-content {
      padding: 50px 140px;
      max-width: 750px;
    }
    form .tf-btn {
      border-radius: 3px;
    }
  }
  .tf-grid-layout {
    .collection-item-centered {
      .tf-btn {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  .flat-wrap-iconbox-v2 {
    padding: 83px 15px;
  }
  .tf-icon-box-v3 {
    .title {
      font-size: 28px;
      line-height: 33.6px;
    }
  }
  .pagination-wrap-spacing {
    margin-top: 76px;
  }
  .border-start-md-0 {
    border-left: 0 !important;
  }
  .masonry-layout-v5 {
    grid-template-columns: repeat(10, 1fr);
    .tf-gallery-image {
      grid-area: span 1 / span 2;
      &.item-1 {
        grid-area: span 2 / span 4;
      }
    }
  }
  .flat-thumbs-testimonial-v2 {
    padding: 85px 110px;
  }
  .tf-icon-box-v2 {
    flex-direction: row;
    .icon {
      font-size: 30px;
    }
  }
  .flat-testimonial-bg {
    padding: 85px 0px 60px;
  }
  .tf-slideshow {
    .card-box-2 {
      .title {
        font-size: 20px;
        line-height: 24px;
      }
      .price {
        margin-bottom: 30px;
      }
    }
  }
  .img-text-3 {
    .tf-content-wrap {
      padding: 20px 20px 20px 20px;
    }
  }
  .banner-parallax {
    height: 780px;
  }
  .slider-home-decor {
    .reverse {
      .img-slider {
        margin-left: unset;
      }
      .content-left {
        left: 50%;
      }
    }
    .content-left {
      .box-content {
        // width: max-content;
        width: 80%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .tf-compare-col {
    min-width: 300px;
  }
  .form-register-wrap {
    width: 551px;
    margin: 0 auto;
  }
  .tf-timeline-content {
    text-align: right;
    width: 50%;
  }
  .tf-timeline-image {
    width: 50%;
  }
  .tf-timeline-inner {
    gap: 100px;
    flex-direction: row;
    &::after,
    &::before {
      position: absolute;
      content: "";
      width: 20px;
      background-color: var(--line);
      height: 1px;
      z-index: 3;
      top: calc(50% + 5px);
    }
    &::before {
      left: calc(50% + 20px);
      transform: translate(-50%);
    }
    &::after {
      right: calc(50% + 20px);
      transform: translate(50%);
    }
    &.tf-timeline-content-end {
      flex-direction: row-reverse;
      .tf-timeline-content {
        text-align: left;
      }
    }
  }
  .tf-timeline-item::before {
    position: absolute;
    content: "";
    top: 50%;
    left: 50%;
    transform: translate(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--primary);
  }
  .tf-store-item {
    padding: 18px 17px 20px;
  }
  .tf-ourstore-content {
    padding-left: 40px;
    padding-right: 20px;
  }
  .tf-accordion-wrap {
    > .content {
      width: 58.33333333%;
    }
    .box {
      width: 41.66666667%;
    }
  }
  .tf-content-left.has-mt {
    margin-top: 40px;
  }
  .tf-filter-item {
    padding: 25px 30px;
  }
  .tf-brands-source-linklist {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    &.style-row {
      .tf-content-brands {
        grid-template-columns: repeat(4, 1fr);
      }
      .tf-item-inner {
        gap: 20px;
      }
    }
  }
  .tf-brands-filter {
    padding-bottom: 47px;
    .tf-tab-link_all {
      padding: 8px 20px;
    }
  }
  .tf-breadcrumb-wrap {
    padding: 25.5px 0;
    .tf-breadcrumb-list {
      .icon {
        margin: 0 10px;
      }
    }
  }
  .tf-slideshow {
    .banner-wrapper {
      .description {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .logo-header img {
    width: 236px;
  }
  .canvas-mb {
    width: 100% !important;
    max-width: 367px;
  }
  .container-full,
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .sw-wrapper-right {
    margin-right: -30px;
  }
  .slider-giftcard {
    .wrap-slider {
      height: 700px;
    }
  }
  .slider-collection {
    .collection-item {
      .collection-title {
        line-height: 54px;
        height: 54px;
        min-width: 100px;
        font-size: 20px;
        .icon {
          display: block;
        }
      }
      .collection-image {
        border-radius: 3px;
      }
    }
  }
  .tf-hero-image-liquid {
    .box-content {
      .tf-btn {
        margin-top: 36px;
      }
    }
  }
  .hover-sw-nav {
    .sw-dots {
      margin-top: 30px;
    }
  }
  .slideshow-effect-zoom {
    padding-top: 62px;
    padding-bottom: 85px;
  }

  // product options
  .card-product {
    .card-product-info {
      padding-top: 15px;
      gap: 10px;
    }
    &.list-layout {
      gap: 30px;
      &:not(:last-child) {
        padding-bottom: 30px;
        margin-bottom: 30px;
      }
      .countdown-box {
        bottom: 56px;
      }
    }
    .countdown-box {
      max-width: 212px;
      bottom: 86px;
    }
    .card-product-wrapper {
      .list-product-btn {
        bottom: 40px;
        &.absolute-2 {
          bottom: 10px;
        }
        &.absolute-3 {
          bottom: 43px;
        }
      }
      .column-left {
        left: 10px;
        top: 10px;
      }
      .column-right {
        right: 10px;
        top: 10px;
      }
      .sold-out {
        height: 83px;
        width: 83px;
        padding: 0 10px;
        span {
          font-size: 14px;
          line-height: 22px;
        }
      }
      .on-sale-wrap {
        top: 10px;
        left: 10px;
        right: 10px;
        .on-sale-item {
          padding: 0 10px;
          min-width: 59px;
          font-size: 14px;
          line-height: 29px;
        }
      }
    }
    &.style-7 {
      .card-product-info {
        padding-top: 10px;
      }
    }
    &.style-8 {
      padding: 10px 10px 30px;
    }
  }
  .wrapper-shop {
    .card-product {
      margin-bottom: 30px;
    }
  }
  .tf-btn-filter {
    padding: 8px 12px;
  }
  .canvas-filter {
    max-width: 410px;
    .canvas-body {
      padding: 32px 36px;
    }
  }
  .widget-facet {
    .facet-title {
      font-size: 20px;
      line-height: 30px;
    }
  }
  .featured-product-item {
    .card-product-info {
      gap: 10px;
    }
  }
  .canvas-sidebar {
    .canvas-header {
      padding: 0 20px;
    }
    .canvas-body {
      padding: 30px;
    }
  }
  .canvas-sidebar-blog {
    .canvas-header {
      .title {
        font-size: 20px;
        line-height: 24px;
      }
    }
    .canvas-body {
      padding: 20px;
    }
  }
  .tf-pagination-wrap {
    padding-top: 60px;
  }
  .tf-grid-layout {
    &.md-col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.md-col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .collection-item {
    .collection-title {
      line-height: 42px;
      height: 42px;
      padding: 0 20px;
    }
    .collection-content {
      bottom: 30px;
    }
    &.style-2 {
      .collection-content {
        bottom: 25px;
      }
    }
    &.style-left {
      .collection-content {
        bottom: 30px;
        left: 15px;
      }
    }
  }
  .collection-item-v2 {
    .collection-content {
      .heading {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .subheading {
        margin-bottom: 10px;
      }
    }
  }
  .collection-item-v3 {
    .collection-image {
      .box-icon {
        bottom: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
      }
    }
    .collection-content {
      margin-top: 20px;
      .title {
        font-size: 18px;
        line-height: 21.6px;
      }
    }
  }
  .collection-item-v4 {
    .collection-content {
      .tf-btn {
        margin-top: 22px;
      }
      .subheading {
        margin-bottom: 8px;
      }
      .heading {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .collection-item-v5 {
    .collection-content {
      left: 25px;
      bottom: 25px;
      .collection-title {
        padding: 12px 14px;
        font-size: 18px;
        line-height: 21.6px;
      }
    }
  }
  .collection-item-circle {
    .collection-content {
      margin-top: 30px;
      .title {
        font-size: 18px;
        line-height: 21.6px;
      }
    }
  }
  .tf-grid-layout {
    gap: 30px;
  }
  .tf-marquee {
    padding-top: 26px;
    padding-bottom: 26px;
    .wrap-marquee {
      -webkit-animation: slide-har 10s linear infinite;
      animation: slide-har 10s linear infinite;
    }
    .marquee-item {
      .text {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
    &.marquee-sm {
      padding-top: 17px;
      padding-bottom: 17px;
      .marquee-item {
        gap: 25px;
      }
    }
    &.marquee-lg {
      p {
        font-size: 60px;
        line-height: 76px;
      }
    }
  }
  // flat tittle
  .flat-title {
    margin-bottom: 50px;
    .title {
      font-size: 32px;
      line-height: 38.4px;
    }
    .sub-title {
      font-size: 16px;
      line-height: 22px;
    }
    &.title-upper {
      .title {
        font-size: 28px;
        line-height: 33.6px;
      }
    }
  }
  .tf-loading-default {
    &.style-2 {
      height: 46px;
      min-width: 130px;
    }
  }
  .flat-seller {
    .grid-layout {
      row-gap: 50px;
    }
  }
  .testimonial-item {
    .text {
      font-size: 18px;
      line-height: 28.8px;
    }

    &.style-column {
      padding: 44px 36px 24px;

      .text {
        margin-bottom: 26px;
      }
      .author {
        margin-bottom: 34px;
      }
      .rating {
        margin-bottom: 16px;
      }
      &.style-2 {
        padding: 36px 32px;
        .text {
          margin-bottom: 20px;
        }
      }
    }
  }
  .flat-iconbox {
    .wrap-iconbox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
    }
  }
  .flat-iconbox-v2 {
    .wrap-iconbox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      .swiper-slide:last-child {
        grid-column: 1 / 3;
      }
    }
    .tf-icon-box {
      .icon {
        margin-bottom: 28px;
      }
      max-width: 450px;
      margin: auto;
      width: 100%;
    }
    &.style-2 {
      .tf-icon-box {
        .icon i {
          font-size: 40px;
        }
      }
    }
  }
  .flat-iconbox-v3 {
    .wrap-iconbox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      .swiper-slide:last-child {
        grid-column: 1 / 3;
      }
    }
    .tf-icon-box {
      .icon {
        width: 60px;
        height: 60px;
        &.w-74 {
          width: 74px;
          height: 74px;
        }
      }
      .content {
        .title {
          font-size: 28px;
          line-height: 34px;
        }
      }
    }
  }
  .wrap-iconbox {
    &.lg {
      .tf-icon-box-v3,
      .tf-icon-box {
        max-width: 450px;
        margin: auto;
        width: 100%;
      }
    }
  }
  .wrap-mobile {
    .grid-mobile-1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
    }
  }
  .flat-price {
    .wrap-price {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
    }
  }
  .masonry-layout {
    display: grid;
    grid-template-areas:
      "item1 item2 item4"
      "item1 item3 item4";
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr;
    .item-1 {
      grid-area: item1;
    }
    .item-2 {
      grid-area: item2;
    }
    .item-3 {
      grid-area: item3;
    }
    .item-4 {
      grid-area: item4;
    }
    .collection-item {
      .img-style {
        height: 100%;
      }
    }
    &.style-2 {
      grid-template-areas:
        "item1 item2"
        "item3 item4";
      .collection-image {
        height: 390px;
      }
      grid-template-columns: 1fr 1fr;
    }
  }
  .tf-img-with-text {
    .tf-image-wrap {
      order: 1;
    }
  }
  .tf-content-wrap {
    padding: 20px;
    .description {
      margin-top: 18px;
      font-size: 16px;
      line-height: 25.6px;
    }
    .tf-btn {
      margin-top: 24px;
    }
    .heading {
      font-size: 40px;
      line-height: 48px;
    }
    .count-down {
      .tf-countdown-v2 {
        .countdown__timer {
          justify-content: flex-start;
        }
      }
    }
  }
  .tf-content-wrap-v2 {
    .description {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .widget-tab-2 {
    gap: 20px;
  }
  .widget-card-store {
    .description {
      margin-bottom: 20px;
      p {
        &:not(:last-child) {
          margin-bottom: 22px;
        }
      }
    }
    .store-item-info {
      padding-left: 32px;
    }
  }
  .tf-countdown-v2 {
    justify-content: flex-end;
    .countdown__item {
      min-height: 60px;
      min-width: 70px;
    }
    .countdown__label {
      font-size: 16px;
      line-height: 25.6px;
    }
    .countdown__value {
      font-size: 26px;
      line-height: 31.2px;
    }
    &.justify-content-end {
      .countdown__timer {
        justify-content: flex-end;
      }
    }
  }
  .flat-wrap-countdown {
    .tf-content-wrap-v2 {
      text-align: left;
    }
  }
  .flat-testimonial-v2 {
    padding-top: 110px;
    padding-bottom: 110px;
  }
  .testimonial-item {
    &.lg {
      .icon {
        font-size: 34px;
      }
    }
  }
  .flat-lookbook-v2 {
    flex-direction: row;
    gap: 30px;
    .col-left,
    .col-right {
      width: 50%;
    }
    .col-right {
      padding: 0 60px;
      .nav-next-slider {
        left: -50px;
      }
      .nav-prev-slider {
        right: -50px;
      }
    }
  }
  .flat-title-lookbook {
    margin-bottom: 30px;
    .heading {
      font-size: 40px;
      line-height: 48px;
    }
  }
  .flat-location {
    .banner-map {
      height: 450px;
    }
    .content {
      max-width: 350px;
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 7%;
      text-align: start;
      .heading {
        font-size: 28px;
        line-height: 33.6px;
      }
      .tf-btn {
        margin-top: 30px;
      }
      .subtext {
        margin-top: 22px;
      }
    }
  }
  .tf-grid-layout-v2 {
    gap: 30px;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    .widget-tab-4 {
      grid-column: span 4;
    }
    .tab-content {
      grid-column: span 8;
    }
  }
  .widget-tab-4 {
    flex-direction: column;
    padding-top: 27px;
    padding-bottom: 27px;
    gap: 0;

    .nav-tab-item {
      &:not(:last-child) {
        .nav-tab-link {
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
      .nav-tab-link {
        justify-content: space-between;
        padding: 10px 0px;
        .text {
          font-size: 22px;
          line-height: 26.4px;
        }
        .count {
          font-size: 14px;
        }
        &.active {
          border-color: var(--main);
        }
      }
    }
  }
  .collection-item-v4 {
    &.style-2 {
      .collection-content {
        bottom: 7%;
        left: 8%;
        right: 8%;
        top: unset;
        transform: unset;
        text-align: left;
        .heading {
          font-size: 32px;
          line-height: 38.4px;
        }
        .subtext {
          margin-top: 14px;
        }
      }
    }
  }
  .canvas-mb {
    .mb-canvas-content {
      min-width: 367px;
    }
  }
  .tf-marquee {
    &.marquee-lg {
      padding: 85px;
    }
  }
  .sw-pagination-wrapper {
    .box-sw-navigation {
      position: absolute;
      right: 0;
      top: 0;
      .sw-dots {
        margin-top: 10px;
      }
    }
  }
  .tf-img-with-text {
    &.style-3 {
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      overflow: hidden;
    }
  }
  .widget-card-store {
    &.type-1 {
      .store-item-info {
        padding: 30px;
      }
      .store-heading {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  .blog-detail .blog-detail-main .tf-article-navigation .icon {
    width: 30px;
    height: 30px;
  }
  .blog-detail .blog-detail-main .tf-article-navigation .item {
    gap: 30px;
  }
  .blog-detail-main {
    .tf-article-navigation {
      h6 {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .box-nav-pagination {
    padding: 12px;
    min-width: 94px;
    .dots-default {
      gap: 8px;
    }
  }
  .dots-default {
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
    }
  }
  .masonry-layout-v3 {
    grid-template-areas:
      "item1 item2"
      "item3 item4";
    .item-1 {
      grid-area: item1;
    }
    .item-2 {
      grid-area: item2;
    }
    .item-3 {
      grid-area: item3;
    }
    .item-4 {
      grid-area: item4;
    }
  }
  .banner-collection-men-wrap {
    .img-wrap {
      height: 780px;
    }
    .card-box {
      padding: 40px 20px;
      .heading {
        font-size: 40px;
        line-height: 48px;
      }
      .text {
        margin-top: 25px;
      }
      .tf-btn {
        margin-top: 35px;
      }
    }
  }
  .card-product {
    &.lg {
      .tf-countdown-v2 {
        .countdown__item {
          .countdown__value {
            font-size: 20px;
          }
        }
      }
    }
  }
  .count-down {
    .tf-countdown-v2 {
      .countdown__item {
        min-width: 60px;
        min-height: 70px;
        padding: 6px 10px;
        .countdown__value {
          font-size: 30px;
          line-height: 36px;
        }
      }
    }
  }
  .tf-marquee {
    &.style-2 {
      .marquee-item {
        gap: calc(52px * 0.85);
      }
    }
    &.marquee-md {
      padding-top: 70px;
      padding-bottom: 80px;
    }
  }
  .tf-flash-sale {
    padding: 95px 20px 20px;
    .heading-flash-sale {
      font-size: 32px;
      line-height: 38.4px;
    }
  }
  .masonry-layout-v4 {
    grid-template-areas:
      "item1 item2"
      "item1 item3";
    gap: 30px;
    .item-1 {
      grid-area: item1;
    }
    .item-2 {
      grid-area: item2;
    }
    .item-3 {
      grid-area: item3;
    }
  }
  .masonry-layout-v6 {
    grid-template-areas:
      "item1 item1 item2 item3"
      "item1 item1 item4 item4";
    gap: 30px;
  }
  .grid-3-layout-md {
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }
  .collection-line-upper {
    .collection-content {
      .collection-title {
        padding: 12px 24px;
      }
      .heading {
        margin-bottom: 17px;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 17px;
      }
    }
  }
  .tf-marquee {
    &.type-md {
      .marquee-item {
        .text {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
  .flat-wrap-tes-text {
    margin-bottom: 30px;
  }
  .testimonial-ver-text {
    gap: 10px;
    .heading {
      margin-bottom: 30px;
    }
    .content {
      font-size: 20px;
      line-height: 32px;
    }
  }
  .tf-thumb-tes {
    .thumb-tes-item {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .slideshow-tee {
    .wrap-slider {
      .box-content {
        .heading {
          font-size: 44px;
          line-height: 52.8px;
        }
      }
    }
    &.tf-slideshow {
      .wrap-slider {
        height: 840px;
      }
    }
  }
  .flat-testimonial-bg-v2 {
    padding: 90px 0px;
    .wrap-content-left {
      .rating {
        margin-bottom: 14px;
      }
    }
    .wrap-content-right {
      margin-top: 30px;
    }
  }
}
@media (min-width: 992px) {
  .row-brand {
    gap: 30px;
  }
  .wd-form-order {
    padding: 30px;
  }
  .list-product-btn {
    .box-icon {
      width: 40px;
      height: 40px;
    }
  }
  .card-product {
    .countdown-box {
      bottom: 90px;
    }
    &:where(.style-2, .style-3) {
      .countdown-box {
        bottom: 100px;
      }
    }
    &.list-layout {
      .countdown-box {
        bottom: 60px;
      }
    }
    .card-product-wrapper {
      .list-product-btn {
        &.absolute-3 {
          bottom: 50px;
        }
      }
    }
    .btn-quick-add {
      height: 40px;
      line-height: 40px;
    }
    &.style-4 {
      .size-list {
        bottom: 40px;
      }
    }
    &.style-height {
      .card-product-wrapper {
        img {
          min-height: 430px;
        }
      }
    }
    &.style-9 {
      .card-product-info {
        .box-icon {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .size-list {
    height: 30px;
    gap: 15px;
    &.style-2 {
      height: 40px;
      gap: 6px;
      span {
        height: 30px;
        min-width: 35px;
      }
    }
  }
  .modal-newleter {
    .modal-content {
      .modal-bottom {
        padding: 39px 37px 30px;
        h6 {
          padding: 0px 40px;
        }
      }
    }
  }
  .collection-item-v2 {
    &.type-small {
      .collection-content {
        inset: 27px;
      }
    }
  }
  .form-checkout {
    .box {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
    .grid-2 {
      gap: 30px;
    }
  }
  .slideshow-tee {
    .wrap-slider {
      .box-content {
        p {
          font-size: 44px;
          line-height: 52.8px;
        }
      }
    }
  }
}
@media (min-width: 1025px) {
  .page-404-wrap {
    .title {
      font-size: 52px;
      line-height: 62px;
    }
    .tf-btn {
      margin-top: 22px;
    }
  }
  .collection-item-v4 {
    &.st-lg {
      .collection-content {
        .heading {
          font-size: 42px;
          line-height: 50.4px;
          margin-bottom: 18px;
        }
      }
    }
  }
  .testimonial-ver-text {
    .heading {
      margin-bottom: 40px;
    }
  }
  .widget-card-store {
    &.type-2 {
      .store-heading {
        font-size: 32px;
        line-height: 38.4px;
      }
    }
  }
  .tf-sticky-btn-atc .tf-sticky-atc-img {
    width: 80px;
    height: 80px;
  }
  .flat-title-lookbook {
    .heading {
      font-size: 52px;
      line-height: 62.4px;
    }
  }
  .flat-location {
    .content {
      padding: 40px;
      padding-bottom: 48px;
    }
  }
  .collection-item-v4 {
    &.style-2 {
      .collection-content {
        .heading {
          font-size: 42px;
          line-height: 50.4px;
          &.fs-52 {
            font-size: 52px;
            line-height: 62px;
          }
        }
      }
    }
  }
  .banner-collection-men-wrap {
    .card-box {
      .heading {
        font-size: 52px;
        line-height: 62.4px;
      }
    }
  }
  .tf-flash-sale {
    .heading-flash-sale {
      font-size: 42px;
      line-height: 50.4px;
    }
  }
  .tf-marquee {
    &.marquee-md {
      p {
        font-size: 68px;
        line-height: 81.6px;
      }
    }
  }
  .slideshow-tee {
    .wrap-slider {
      .box-content {
        .heading {
          font-size: 80px;
          line-height: 96px;
        }
        p {
          font-size: 80px;
          line-height: 96px;
        }
      }
    }
  }
}
@media (min-width: 1150px) {
  .page-404-wrap {
    padding: 100px 0 75px;
    .image {
      margin-bottom: 72px;
    }
    .tf-btn {
      margin-top: 38px;
    }
  }
  .grid-2-lg {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .img-text-3 {
    &.img-text-3-style-2 {
      .tf-image {
        padding: 50px 0px;
      }
      .subheading {
        font-size: 18px;
      }
      .heading {
        font-size: 46px;
        line-height: 55.2px;
      }
    }
  }
  .slider-baby {
    .wrap-slider {
      .subheading {
        font-size: 30px;
        line-height: 36px;
      }
      .heading {
        margin-bottom: 38px;
      }
    }
  }

  .tf-grid-layout {
    &.lg-col-4 {
      grid-template-columns: repeat(4, 1fr);
    }
    &.lg-col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  #header {
    .nav-icon {
      .cart-lg {
        padding-left: 15px;
        border-left: 1px solid var(--line);
      }
    }
  }
  .collection-item-v4 {
    &.st-lg {
      .collection-content {
        .subtext {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }

  .collection-item-v6 {
    .collection-content {
      padding: 24px 20px 26px;
      .heading {
        font-size: 28px;
        line-height: 33.6px;
      }
      .subheading {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .collection-item-circle {
    &.has-bg {
      padding: 20px 0px;
    }
  }
  .flat-categories-bg {
    padding: 56px 50px;
  }
  .tf-countdown-v3 {
    padding: 10px 16px;
  }
  .tf-form-sneaker {
    .box-content {
      max-width: 950px;
      padding: 81px 182px 89px 231px;
      z-index: 10;
    }
  }
  .tf-grid-layout {
    .collection-item-centered {
      .tf-btn {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
  .flat-wrap-iconbox-v2 {
    padding: 98px 15px;
  }
  .tf-icon-box-v3 {
    .title {
      font-size: 42px;
      line-height: 50.4px;
    }
    .desc {
      font-size: 18px;
      line-height: 28.8px;
    }
  }
  .flat-testimonial-bg-v2 {
    padding: 106px 0px;
    .wrap-content-right {
      margin-top: 0px;
    }
  }
  .testimonial-item {
    &.style-column {
      &.style-2 {
        .text {
          margin-bottom: 70px;
        }
      }
    }
  }
  .slideshow-tee {
    .wrap-slider {
      .box-content {
        .heading {
          font-size: 150px;
          line-height: 180px;
        }
      }
    }
  }

  .lg-mt-50 {
    margin-top: 50px;
  }

  .flat-wrap-tes-text {
    margin-bottom: 88px;
  }
  .testimonial-ver-text {
    .content {
      font-size: 28px;
      line-height: 44.8px;
    }
  }
  .tf-thumb-tes {
    .thumb-tes-item {
      padding-left: 42px;
      padding-right: 42px;
      img {
        max-width: 157px;
      }
    }
  }
  .testimonial-item {
    &.style-row {
      padding-right: 40px;
      .rating {
        margin-bottom: 18px;
      }
      .text {
        margin-bottom: 38px;
      }
    }
  }
  .lookbook-sw {
    .navigation-sw-dot {
      width: 24px;
      height: 24px;
      @include transition3;
      span {
        width: 8px;
        height: 8px;
        @include transition3;
      }
      &:hover {
        width: 32px;
        height: 32px;
        span {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .flat-activewear-lookbook {
    .inner-sw-lookbook {
      .tf-btn {
        padding: 0 94px;
      }
    }
  }

  .flat-thumbs-testimonial-v2 {
    .box-left {
      .text {
        font-size: 28px;
        line-height: 44.8px;
      }
    }
  }

  .col-xl-8 {
    .flat-accordion {
      .flat-toggle {
        .toggle-title {
          font-size: 18px;
          line-height: 21.6px;
        }
      }
    }
  }
  .widget-card-store {
    &.type-2 {
      .store-heading {
        font-size: 42px;
        line-height: 50.4px;
      }
    }
  }
  .flat-testimonial-bg {
    padding: 100px 0px 68px;
  }
  .img-text-3 {
    .tf-content-wrap {
      padding: 20px 20px 20px 90px;
    }
  }
  .sw-pagination-wrapper {
    .box-sw-navigation {
      .sw-dots {
        margin-top: 15px;
      }
    }
  }
  .collection-item-circle {
    .tf-shopall-icon {
      .icon {
        @include transition3;
      }
      &:hover {
        border-color: var(--primary);
        .icon {
          color: var(--primary);
        }
      }
    }
  }
  .tf-compare-field,
  .tf-compare-value {
    padding: 16px 34px;
  }
  .tf-compare-item {
    padding: 16px 34px;
    .tf-compare-group-btns {
      a span {
        display: block;
      }
    }
  }
  .tf-compare-col {
    min-width: 360px;
  }
  .tf-login-wrap {
    .tf-login-content,
    .tf-login-form {
      width: 551px;
      margin: 0 auto;
    }
    form {
      button {
        max-width: 270px;
      }
    }
  }
  .tf-timeline-inner {
    gap: 190px;
    padding-top: 90px;
    padding-bottom: 90px;
    &::before,
    &::after {
      width: 40px;
    }
    &::before {
      left: calc(50% + 35px);
    }
    &::after {
      right: calc(50% + 35px);
    }
  }
  .tf-store-item {
    padding: 28px 27px 30px;
  }
  .tf-ourstore-content {
    padding-left: 93px;
    &.pl-124 {
      padding-left: 124px;
    }
  }
  .tf-accordion-wrap {
    > .content {
      width: 58.33333333%;
    }
    .box {
      width: 33.33%;
    }
  }
  .form-contact.mw-705 {
    max-width: 705px;
  }
  .tf-content-left {
    margin-left: 122px;
    &.has-mt {
      margin-top: 72px;
    }
  }
  .tf-content-right {
    margin-right: 122px;
  }
  .tf-brands-source-linklist {
    grid-template-columns: repeat(4, 1fr);
    &.style-row {
      .tf-content-brands {
        grid-template-columns: repeat(6, 1fr);
      }
    }
  }
  .tf-pickup-availability-list {
    gap: 42px;
  }
  .tf-breadcrumb-wrap {
    padding: 30px 0;
  }
  .tf-categories-wrap {
    justify-content: center;
    .tf-categories-container {
      overflow-x: auto;
    }
  }
  .animate-hover-btn:hover:after {
    animation: shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);
  }
  .lg-px_40 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .sw-wrapper-right {
    margin-right: -40px;
  }
  .tf-md-hidden {
    display: block;
  }
  .tf-lg-hidden {
    display: none;
  }
  .header-default {
    .wrapper-header {
      min-height: 94px;
    }
  }
  .header-style-2 {
    .wrapper-header {
      min-height: 68px;
    }
  }
  .header-style-3 {
    .wrapper-header {
      min-height: 86px;
    }
  }
  .header-style-4 {
    .wrapper-header {
      min-height: 103px;
    }
  }
  .header-absolute {
    margin-bottom: 0px;
  }
  .tf-slideshow {
    .banner-wrapper {
      .tf-btn {
        margin-top: 40px;
      }
    }
  }
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
  .container-full {
    padding-left: 40px;
    padding-right: 40px;
  }
  .slider-collection {
    .collection-item {
      .collection-title {
        line-height: 64px;
        height: 64px;
        min-width: 297px;
      }
    }
  }

  //product option
  .card-product {
    &.type-line-padding {
      padding: 40px 30px;
    }
    .card-product-info {
      padding-top: 20px;
      gap: 12px;
      .price-primary {
        font-size: 20px;
        line-height: 20px;
        color: var(--red_1);
        font-weight: 600;
      }
    }
    &.list-layout .countdown-box,
    .countdown-box {
      bottom: 20px;
      padding: 10px;
      max-height: 42px;
    }
    .size-list {
      height: 33px;
    }
    .btn-quick-add {
      transform: translateY(20px);
      opacity: 0;
      visibility: hidden;
      height: 42px;
      line-height: 42px;
      &:hover {
        background-color: var(--white);
        color: var(--main);
      }
    }
    &:not(.list-layout) {
      .box-icon {
        transform: translateY(20px);
        opacity: 0;
        visibility: hidden;
        &:nth-child(1) {
          transition: transform 0.4s ease 0s, opacity 0.4s ease 0s;
        }
        &:nth-child(2) {
          transition: transform 0.4s ease 0.2s, opacity 0.4s ease 0s;
        }
        &:nth-child(3) {
          transition: transform 0.4s ease 0.3s, opacity 0.4s ease 0s;
        }
        &:nth-child(4) {
          transition: transform 0.4s ease 0.4s, opacity 0.4s ease 0s;
        }
      }
    }

    &:not(.list-layout) {
      .size-list {
        opacity: 0;
        visibility: hidden;
        transform: translateY(100%);
      }
    }

    .card-product-wrapper {
      .list-product-btn {
        &.absolute-2 {
          bottom: 20px;
        }
        &.absolute-3 {
          bottom: 52px;
        }
      }
      .column-left {
        .box-icon {
          transform: translate(-20px);
        }
      }
      .column-right {
        .box-icon {
          transform: translate(20px);
        }
      }
      .sold-out {
        height: 93px;
        width: 93px;
      }
      .on-sale-wrap {
        top: 15px;
        left: 15px;
        right: 15px;
      }
    }
    &.style-4 {
      .column-right {
        .box-icon {
          transform: translateY(20px);
        }
      }
      .size-list {
        bottom: 42px;
      }
    }
    &.style-6 {
      .list-product-btn {
        gap: 10px;
      }
      .card-product-info {
        margin-bottom: -52px;
        background-color: transparent;
      }
    }
    &.style-7 {
      .btn-quick-add {
        transform: translateY(-100%);
      }
      .card-product-info {
        padding-top: 15px;
        transform: translateY(-52px);

        &::before {
          content: "";
          position: absolute;
          z-index: -1;
          width: calc(100% + 16px);
          height: calc(100% + 9px);
          left: -8px;
          right: -8px;
          pointer-events: none;
          background-color: var(--white);
          transition: 0.4s ease 0s;
          opacity: 0;
          visibility: hidden;
          top: 0;
          bottom: -8px;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 9px 0px;
        }
      }
      &:hover {
        .card-product-info,
        .card-product-info::before {
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
          margin: 0;
          pointer-events: auto;
        }
      }
    }
    &.style-8 {
      .card-product-wrapper {
        .on-sale-wrap {
          top: 10px;
          left: 10px;
          right: 10px;
        }
      }
    }
    &.style-9 {
      .card-product-info {
        .box-icon {
          width: 42px;
          height: 42px;
        }
        .title {
          font-weight: 600;
        }
        .price {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
    &:hover {
      .countdown-box {
        transform: translateY(100%);
        opacity: 0;
        visibility: hidden;
      }

      .btn-quick-add,
      .box-icon,
      .size-list {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
      }

      .column-right,
      .column-left {
        .box-icon {
          transform: translate(0px);
        }
      }
    }
    &.lg {
      .card-product-info {
        .title {
          font-size: 20px;
          line-height: 24px;
        }
        .price {
          font-size: 20px;
          line-height: 24px;
          .old-price {
            opacity: 0.5;
          }
        }
      }
      .tf-countdown-v2 .countdown__item .countdown__value {
        font-size: 26px;
      }
    }
  }
  .list-product-btn {
    gap: 6px;
    .box-icon {
      width: 42px;
      height: 42px;
    }
    &.column-left {
      gap: 6px;
    }
    &.column-right {
      gap: 6px;
    }
  }
  .size-list {
    &.style-2 {
      height: 42px;
      gap: 9px;
    }
  }

  .tf-shop-control {
    margin-bottom: 28px;
  }
  .tf-btn-filter {
    padding: 10px 12px;
    &:hover {
      border-color: var(--main);
    }
  }
  .tf-dropdown-sort {
    padding: 10px 18px;
    min-width: 190px;
  }
  // shop
  .tf-row-flex {
    .tf-shop-sidebar {
      width: calc(25% - 15px);
    }
    .tf-shop-content {
      width: calc(100% - 25% - 15px);
    }
  }
  .featured-product-item {
    .card-product-info {
      gap: 12px;
    }
  }
  .tf-grid-layout {
    &.lg-col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
    &.lg-col-2 {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .collection-item {
    .collection-title {
      line-height: 46px;
      height: 46px;
      padding: 0 30px;
      font-size: 18px;
      &.px-16 {
        padding: 0 16px;
      }
    }
    .collection-content {
      bottom: 36px;
    }
    &.large {
      .collection-content {
        bottom: 46px;
      }
    }
  }
  .collection-item-v2 {
    .collection-content {
      inset: 40px;
      .heading {
        font-size: 28px;
        line-height: 33.6px;
        margin-bottom: 13px;
      }
      .subheading {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 13px;
      }
    }
  }
  .flat-banner-cls-kid {
    .collection-item-v2 {
      .collection-content {
        inset: 30px;
      }
    }
  }
  .collection-item-v3 {
    .collection-image {
      .box-icon {
        width: 45px;
        height: 45px;
      }
    }
  }
  .collection-item-v4 {
    .collection-content {
      .tf-btn {
        margin-top: 30px;
      }
      .subheading {
        margin-bottom: 11px;
      }
      .heading {
        font-size: 28px;
        line-height: 33.6px;
      }
    }
  }
  .collection-item-v5 {
    .collection-content {
      .collection-title {
        padding: 14px 16px;
        gap: 21px;
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }
  }
  .collection-item-circle {
    .collection-content {
      margin-top: 45px;
    }
  }
  .collection-line-upper {
    .collection-content {
      .heading {
        margin-bottom: 22px;
        font-size: 28px;
        line-height: 33.6px;
      }
    }
  }
  .tf-marquee {
    padding-top: 31px;
    padding-bottom: 31px;
    .wrap-marquee {
      -webkit-animation: slide-har 15s linear infinite;
      animation: slide-har 15s linear infinite;
    }
    &.marquee-sm {
      padding-top: 20px;
      padding-bottom: 20px;
      .marquee-item {
        padding-left: 15px;
        padding-right: 15px;
        gap: 30px;
      }
    }
    &.marquee-lg {
      p {
        font-size: 80px;
        line-height: 96px;
      }
    }
    &.marquee-xl {
      .marquee-item {
        gap: 70px;
        padding: 0 35px;
        .text {
          font-size: 52px;
          line-height: 62.4px;
        }
      }
    }
  }
  .blog-sidebar-main,
  .blog-list-main {
    display: flex;
    gap: 30px;
    > .list-blog {
      width: calc(75% - 15px);
    }
    > .tf-section-sidebar {
      width: calc(25% - 15px);
    }
  }
  // flat tittle
  .flat-title {
    margin-bottom: 60px;
    .title {
      font-size: 42px;
      line-height: 50.4px;
    }
    &.lg {
      .sub-title {
        font-size: 20px;
        line-height: 22px;
      }
    }
  }
  .flat-title-v4 {
    margin-bottom: 50px;
  }
  .flat-title-v5 {
    margin-bottom: 80px;
  }
  .flat-seller {
    .grid-layout {
      row-gap: 80px;
    }
  }
  .flat-iconbox {
    .wrap-iconbox {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .wrap-mobile {
    .grid-mobile-1 {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
    }
  }
  .flat-iconbox-v2 {
    .wrap-iconbox {
      grid-template-columns: repeat(3, 1fr);
      gap: 0;
      .swiper-slide {
        &:last-child {
          grid-column: unset;
        }
        &:not(:last-child) {
          .tf-icon-box {
            border-right: 1px solid var(--line);
          }
        }
      }
    }
    &.style-2 {
      .wrap-iconbox {
        gap: 30px;
      }
    }
    .tf-sw-mobile {
      padding-top: 36px;
      padding-bottom: 36px;
    }
    .tf-icon-box {
      .icon {
        margin-bottom: 34px;
      }
    }
  }
  .flat-iconbox-v3 {
    .wrap-iconbox {
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      .swiper-slide {
        &:last-child {
          grid-column: unset;
        }
      }
    }
    &.lg {
      .tf-icon-box .icon {
        width: 99px;
        height: 99px;
        i {
          font-size: 40px;
        }
      }
      .wrap-iconbox {
        gap: 30px;
      }
    }
  }
  .masonry-layout {
    gap: 30px;
    &.style-2 {
      grid-template-areas:
        "item1 item2 item4"
        "item1 item3 item4";
      grid-template-columns: 1fr 1fr 1fr;
      .item-4,
      .item-1 {
        .collection-image {
          height: 850px;
        }
      }
      .item-2,
      .item-3 {
        .collection-image {
          height: 410px;
        }
      }
      .collection-image {
        height: 100%;
      }
    }
  }
  .tf-img-with-text {
    .tf-content-wrap {
      place-self: center start;
    }
    &.style-5 {
      .tf-content-wrap {
        padding-left: 78px;
      }
    }
  }
  .tf-image-wrap {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: -100%;
      left: 0;
      width: 50%;
      height: 100%;
      background: rgb(0 0 0);
      transition: 0.25s;
      z-index: 3;
      opacity: 0.1;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: -100%;
      right: 0;
      width: 50%;
      height: 100%;
      background: rgb(0 0 0);
      transition: 0.25s;
      z-index: 3;
      opacity: 0.1;
    }
    &:hover {
      &::after {
        bottom: 0;
        transition-delay: 0.25s;
      }
      &::before {
        top: 0;
      }
    }
  }
  .tf-content-wrap {
    padding-left: 0;
    .heading {
      font-size: 52px;
      line-height: 62.4px;
      &.fs-42 {
        font-size: 42px;
        line-height: 50px;
      }
    }
    .description {
      margin-top: 24px;
    }
    .tf-btn {
      margin-top: 34px;
    }
  }
  .widget-tab-2 {
    gap: 30px;
    margin-bottom: 57px;
    .nav-tab-item {
      a {
        padding-bottom: 14px;
      }
    }
  }
  .widget-card-store {
    .store-heading {
      margin-bottom: 31px;
    }
    .description {
      p {
        &:not(:last-child) {
          margin-bottom: 22px;
        }
      }
    }
    .store-item-info {
      padding-left: 122px;
    }
    &.type-1 {
      .store-heading {
        font-size: 52px;
        line-height: 62.4px;
      }
    }
  }
  .tf-countdown-v2 {
    .countdown__item {
      padding: 0px 20px;
      min-height: 84px;
      min-width: 90px;
    }

    .countdown__value {
      font-size: 32px;
      line-height: 38.4px;
    }
  }
  .flat-bg-collection {
    .collection-other-link {
      margin-top: 50px;
    }
  }
  .flat-testimonial-v2 {
    padding-top: 130px;
    padding-bottom: 130px;
  }
  .testimonial-item {
    .icon {
      margin-bottom: 40px;
    }
    &.lg {
      .text {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 36px;
      }
    }
    .rating {
      margin-bottom: 26px;
    }
    .divider {
      margin-bottom: 22px;
    }
    &.lg-2 {
      .icon {
        margin-bottom: 22px;
      }
      .rating {
        margin-bottom: 24px;
      }
      .text {
        margin-bottom: 24px;
      }
      .box-author {
        margin-bottom: 40px;
      }
    }
    &.lg-3 {
      .text {
        font-size: 28px;
        line-height: 44px;
      }
    }
  }
  .wrapper-thumbs-testimonial {
    .box-right {
      padding-right: 80px;
    }
  }
  .gallery-item {
    .box-icon {
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      &::before {
        opacity: 1;
        visibility: visible;
      }
      .box-icon {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .tf-icon-box {
    &.style-row {
      .icon {
        width: 74px;
        height: 74px;
      }
    }
  }
  .flat-lookbook-v2 {
    .col-right {
      padding: 0 90px;
      .nav-next-slider {
        left: -60px;
      }
      .nav-prev-slider {
        right: -60px;
      }
    }
  }
  .slider-wrap-lookbook {
    // .inner-sw-lookbook {
    //     margin: 0 60px;
    // }
  }
  .flat-title-lookbook {
    margin-bottom: 40px;
  }
  .flat-location {
    .banner-map {
      height: 600px;
    }
    .content {
      max-width: 460px;
    }
  }
  .widget-tab-4 {
    padding: 46px 30px 58px;

    .nav-tab-item {
      .nav-tab-link {
        padding: 15px 0px;
        .text {
          font-size: 26px;
          line-height: 31.2px;
        }
      }
    }
  }
  .collection-item-v4 {
    &.style-2 {
      .collection-content {
        bottom: 15%;
        left: 10%;
        right: 10%;
        .subtext {
          margin-top: 18px;
          font-size: 20px;
          line-height: 32px;
        }
        .tf-btn {
          margin-top: 28px;
        }
      }
    }
    &.style-3 {
      .collection-content {
        bottom: 7%;
        left: 7%;
        right: 7%;
      }
    }
    &.style-4 {
      .collection-content {
        bottom: 10%;
      }
    }
  }
  .lg_py_30 {
    padding: 30px 0;
  }
  .lg_fs_18 {
    font-size: 18px;
  }
  .tf-cart-countdown {
    margin-bottom: 68px;
    p {
      font-size: 20px;
      line-height: 32px;
    }
    .timer-count {
      font-size: 20px;
      line-height: 32px;
      min-width: 117px;
    }
  }
  .tf-page-cart-wrap {
    display: grid;
    grid-template: repeat(2, auto) / 66% 1fr;
    column-gap: 3rem;
    &.layout-2 {
      grid-template: repeat(2, auto) / 60% 1fr;
    }
  }
  .tf-cart-item {
    .cart-info {
      .cart-title {
        font-size: 16px;
        list-style: 22.4px;
      }
    }
  }
  .tf-cart-footer-inner {
    position: sticky;
    top: 100px;
    transition: top 0.3s ease;
  }
  .tf-marquee {
    &.marquee-lg {
      padding: 100px;
    }
  }
  .collection-item-v4 {
    &.lg {
      .collection-content {
        left: 60px;
        right: 60px;
      }
    }
  }
  .widget-card-store {
    &.type-1 {
      .store-item-info {
        padding: 50px;
      }
    }
  }
  .footer {
    &.background-black {
      .footer-body {
        padding-top: 60px;
      }
    }
  }
  .blog-detail .blog-detail-main .tf-article-navigation .icon {
    width: 46px;
    height: 46px;
  }
  .flat-wrap-iconbox {
    padding: 109px 90px 132px;
  }
  .lookbook-item {
    .tf-pin-btn {
      &::after,
      &::before {
        position: absolute;
        content: "";
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        border-radius: 9999px;
      }
      &::after {
        animation-delay: 0.5s;
      }
      &::before {
        animation-delay: 0.9s;
      }
      &:hover {
        &::after,
        &::before {
          animation: ripple 3s infinite;
        }
      }
      &.pin-black {
        &:hover {
          &::after,
          &::before {
            animation: ripple_black 3s infinite;
          }
        }
      }
    }
  }
  .masonry-layout-v3 {
    gap: 30px;
    grid-template-areas:
      "item1 item2 item3"
      "item1 item2 item4";
  }
  .banner-collection-men-wrap {
    .card-box {
      padding: 80px 44px;
      max-width: 700px;
    }
    .box-content {
      left: 0;
      right: 0;
    }
  }
  .widget-tab-3 {
    &.style-2 {
      gap: 45px;
    }
  }
  .tf-marquee {
    &.style-2 {
      .marquee-item {
        gap: 52px;
      }
    }
  }
  .tf-flash-sale {
    padding: 60px 30px;
    .heading-flash-sale {
      margin-left: 35px;
      padding: 4px 20px;
      gap: 15px;
    }
  }
}

@media (min-width: 1441px) {
  #header {
    .nav-icon {
      .cart-lg {
        .icon {
          font-size: 26px;
        }
        padding-left: 22px;
      }
    }
  }
  .collection-item-circle {
    &.has-bg-2 {
      padding: 35px 45px;
    }
  }
  .box-video-wrap {
    max-width: 612px;
    margin-left: auto;
  }
  .flat-testimonial-bg-v2 {
    .wrap-content-right {
      padding-left: 50px;
    }
  }

  .slider-home-decor {
    .content-left {
      .box-content {
        width: max-content;
      }
    }
  }
  .card-product {
    &.list-layout .countdown-box,
    .countdown-box {
      bottom: 25px;
    }
    .card-product-wrapper {
      .list-product-btn {
        bottom: 48px;
        &.absolute-2 {
          bottom: 25px;
        }
        &.absolute-3 {
          bottom: 57px;
        }
      }
    }
  }
  .list-product-btn {
    &.column-left {
      gap: 8px;
    }
    &.column-right {
      gap: 8px;
    }
  }
  .flat-lookbook-v2 {
    .col-left {
      width: calc(7 / 12 * 100% - 15px);
    }
    .col-right {
      width: calc(5 / 12 * 100% - 15px);
      margin-top: 63px;
    }
  }
  .flat-title-lookbook {
    margin-bottom: 60px;
  }
  .widget-tab-4 {
    padding: 66px 79px 78px 56px;

    .nav-tab-item {
      .nav-tab-link {
        padding: 20px 0px;
        .text {
          font-size: 30px;
          line-height: 36px;
        }
        &:hover {
          .icon,
          span {
            color: var(--primary);
          }
        }
      }
    }
  }
  .header-default {
    .box-nav-ul {
      gap: 30px;
      &.gap-40 {
        gap: 40px;
      }
    }
  }
  .tf-flash-sale {
    padding: 60px 40px;
    .heading-flash-sale {
      margin-left: 55px;
      padding: 4px 30px;
      gap: 20px;
    }
  }
  .card-product {
    &.style-9 {
      .card-product-info {
        .box-icon {
          width: 57px;
          height: 57px;
        }
      }
    }
  }
}
@media (min-width: 1600px) {
  .flat-lookbook-v2 {
    .col-right {
      margin-left: 20px;
    }
  }
}

@media (min-width: 1660px) {
  .radius-100 {
    border-radius: 100px;
  }
}

// max-width:
@media only screen and (max-width: 1800px) {
}

@media only screen and (max-width: 1599px) {
  .wrap-carousel {
    .nav-next-slider {
      left: -24px;
    }
    .nav-prev-slider {
      right: -24px;
    }
    &.style-2 {
      .nav-next-slider {
        left: -50px;
      }
      .nav-prev-slider {
        right: -50px;
      }
    }
  }
}

@media only screen and (max-width: 1470px) {
  .footer {
    .footer-wrap {
      .footer-body {
        &::before {
          left: 15px;
          right: 15px;
          transform: unset;
          width: unset;
        }
      }
    }
  }
  .has-line-bottom {
    position: relative;
    &::after {
      left: 15px;
      right: 15px;
      transform: unset;
      width: unset;
    }
  }
}

@media only screen and (max-width: 1399px) {
  .canvas-compare {
    .close-popup {
      top: 4px;
      right: 4px;
    }
  }
  .card-product {
    .count-down {
      .countdown__item {
        min-width: 42px;
        min-height: 60px;
        padding: 0;
      }
    }
  }
}
@media only screen and (max-width: 1200px) {
  .tf-top-bar_wrap .tf-social-icon,
  .tf-top-bar_wrap .top-bar-language,
  .tf-top-bar_left {
    display: none !important;
  }
  .tf-top-bar .grid-3 {
    grid-template-columns: 1fr;
  }

  .wrapper-header {
    .nav-icon {
      gap: 14px;
    }
  }
  .footer {
    .footer-logo,
    .footer-heading {
      margin-top: 40px;
      margin-bottom: 22px;
    }
    &.has-all-border {
      .footer-col,
      .footer-newsletter {
        width: 100% !important;
        border-right: 0 !important;
        padding: 0px 20px 0 0 !important;
      }
    }
    &.has-border {
      .footer-col,
      .footer-newsletter {
        width: 50% !important;
        border-right: 0 !important;
        padding: 0px 20px 0 0 !important;
      }
    }
  }

  .mega-menu .row-demo {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1149px) {
  .card-product {
    .count-down {
      .countdown__timer {
        gap: 5px;
      }
      .countdown__item {
        min-height: 50px;
        padding: 0;
      }
    }
  }
  .sidebar-mobile-append {
    .wrap-price {
      display: none;
    }
  }

  .flat-testimonial-bg-v2 {
    .wrap-content-right {
      .sw-wrapper-right {
        margin-right: 0;
      }
    }
  }
  .header-style-2 {
    .header-bottom {
      .box-navigation {
        display: none;
      }
    }
  }
  .header-style-3 {
    .box-navigation {
      display: none;
    }
    .wrap-header-left {
      justify-content: center;
    }
  }
  .header-style-4 {
    &#header {
      .nav-icon {
        .nav-search {
          display: inline-flex;
        }
        .nav-account,
        .nav-wishlist,
        .nav-compare {
          display: none;
        }
      }
    }
  }
  .logo-header {
    display: flex;
    justify-content: center;
  }

  .mega-menu .row-demo {
    grid-template-columns: repeat(3, 1fr);
  }
  .header-style-3 .wrap-header-left {
    justify-content: center;
  }
  .tf-sticky-btn-atc {
    bottom: 67px;
  }
  .tf-accordion-wrap {
    gap: 30px;
  }
  .tf-product-bundle-wrap {
    padding: 14px;
  }
  .tf-bundle-product-item {
    gap: 10px;
  }
  .tf-product-bundle-total-submit .compare-at-price {
    font-size: 22px;
  }
  .tf-model-viewer model-viewer {
    height: 780px;
  }
  .toggle-title {
    font-size: 14px !important;
    line-height: 22.4px !important;
  }
  .tf-img-video-text {
    .content-wrap {
      padding: 30px 15px;
      li:not(:last-child) {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .card-product.style-price {
    .card-product-info {
      padding: 15px 20px 0 20px;
      .title {
        font-size: 16px;
      }
    }
    .tf-price-table-contents {
      padding: 20px;
      ul li {
        font-size: 14px !important;
      }
      .tf-price-table-btn {
        margin-top: 20px !important;
      }
    }
  }
  .flat-lookbook-v3 {
    form {
      max-width: 90%;
    }
    .tf-lookbook {
      height: 304px;
    }
    .sw-dots {
      margin-bottom: 16px;
    }
  }
  .tf-bundle-product-item {
    &.type-lg {
      gap: 20px;
      margin-top: 15px;
      padding-bottom: 15px;
      border-bottom: 0;
    }
  }
  .form-sign-in {
    .tf-login-form {
      .bottom {
        flex-direction: column;
      }
    }
  }
  .canvas-search {
    max-width: 400px;
    padding-top: 10px;
    .tf-search-head {
      padding: 0 15px;
      margin-bottom: 22px;
      .title {
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
    .tf-search-content {
      padding: 0 15px 16px 15px;
    }
    .tf-search-content-title {
      margin-bottom: 22px !important;
    }
    .tf-col-quicklink {
      margin-bottom: 17px;
    }
    .tf-search-hidden-inner {
      padding-top: 0;
    }
  }

  .tf-mini-cart-tool-openable .tf-mini-cart-tool-content {
    padding: 20px;
  }
  .modal-shopping-cart {
    .modal-content {
      max-width: 420px !important;
    }
    .header {
      margin: 0 15px;
    }
    .tf-mini-cart-threshold {
      margin: 0 15px;
      .tf-progress-msg {
        font-size: 14px;
        line-height: 22.4px;
      }
    }
    .tf-mini-cart-item {
      margin: 0 15px;
      .tf-mini-cart-info {
        a {
          font-size: 14px;
          line-height: 14px;
        }
      }
    }
    .tf-minicart-recommendations {
      padding: 15px;
      margin: 15px;
    }
    .tf-mini-cart-bottom-wrap {
      padding: 10px 15px 15px;
      .tf-mini-cart-view-checkout {
        flex-direction: column;
      }
    }
  }
  .slider-collection {
    margin-bottom: 10px;
  }
  .tf-product-modal .modal-dialog .modal-content {
    padding: 20px;
  }
  #quick_view {
    .tf-product-media-wrap {
      width: 50% !important;
      padding-right: 15px;
    }
    .tf-product-info-wrap {
      .tf-product-info-list {
        padding: 20px 30px 20px 15px;
      }
    }
    .modal-content {
      margin: 0 !important;
      .tf-product-info-title {
        a {
          font-size: 26px !important;
        }
      }
    }
  }
  #ask_question {
    input {
      height: 42px !important;
    }
    textarea {
      height: 120px;
    }
  }
  .tf-product-info-wrap {
    .tf-product-info-title {
      h5 {
        font-size: 26px;
        line-height: 31.2px;
      }
    }
  }
  .tf-product-modal {
    .modal-dialog {
      .modal-content {
        padding: 20px;
        border-radius: 3px;
      }
    }
  }
  #compare_color {
    .modal-dialog {
      max-width: min(900px, 90vw);
      min-width: min(900px, 90vw);
      .modal-content {
        padding: 20px;
      }
    }
  }
  .tf-compare-color-item {
    padding: 14px 15px 20px;
    min-width: 280px;
    max-width: 280px;
  }
  .blog-article-item {
    &.style-row {
      .article-thumb {
        width: 300px;
        height: 300px;
      }
    }
  }
  .widget-tabs {
    &.style-has-border {
      .widget-menu-tab {
        gap: 10px 25px;
        margin: 0 15px;
        .item-title {
          padding: 12px 0;
          font-size: 14px;
          line-height: 18px;
        }
      }
      .widget-content-tab {
        .widget-content-inner {
          padding: 20px 15px;
        }
      }
    }
    &.style-two-col {
      display: block;
      .widget-menu-tab {
        flex-direction: row;
        gap: 10px 25px;
        border: 0;
        .item-title {
          padding: 12px 0;
          font-size: 14px;
          line-height: 18px;
          &::after {
            top: unset;
            bottom: 0;
            left: 0;
            width: 0;
            height: 2px !important;
          }
          &.active {
            &::after {
              width: 100%;
            }
          }
        }
      }
      .widget-content-tab {
        margin-top: 20px;
        border-radius: 5px;
        .widget-content-inner {
          padding: 20px 15px;
        }
      }
    }
  }
  .tf-product-des-demo {
    grid-template-columns: unset;
  }

  .thumbs-slider1,
  .thumbs-slider2,
  .thumbs-slider3,
  .thumbs-slider4,
  .thumbs-slider {
    flex-direction: column !important;
    > div {
      width: 100%;
    }
    .tf-product-media-thumbs {
      order: 1;
      .swiper-slide {
        width: auto;
        height: 107px;
      }
    }
  }
  .tf-product-info-list {
    padding-left: 0;
  }
  .tf-product-info-countdown {
    .countdown-wrap {
      min-width: unset;
      .countdown__timer {
        flex-wrap: wrap;
      }
    }
  }
  .tf-product-delivery {
    margin-bottom: 30px;
  }
  .tf-product-fbt-wrap {
    margin-top: 30px;
    .title {
      margin-bottom: 20px;
    }
    form {
      gap: 30px;
    }
  }

  .wrap-sidebar-mobile {
    display: none;
  }
  .btn-sidebar-style2,
  .btn-sidebar-mobile {
    display: block;
  }

  .tf-toolbar-bottom.type-1150 {
    display: flex;
  }
  .announcement-bar {
    padding-right: 40px;
    .close-announcement-bar {
      right: 0;
    }
  }
  .wrap-slider {
    .box-content {
      p {
        margin-bottom: 32px;
      }
    }
  }
  .tf-slideshow {
    .sw-absolute-2 {
      .sw-dots {
        justify-content: center;
      }
    }
    .card-box {
      .tf-btn {
        font-size: 14px;
        line-height: 46px;
      }
      .heading {
        br {
          display: none;
        }
      }
      background-color: rgba(0, 0, 0, 0.3);
      padding: 20px;
      border-radius: 10px;
      width: max-content;
    }
    .sw-absolute-3 {
      left: 0;
      right: 0;
      .sw-dots {
        flex-direction: row;
        justify-content: center;
      }
    }
  }
  .sw-dots {
    &.style-2 {
      span {
        &::before {
          width: 6px;
          height: 6px;
        }
        &.swiper-pagination-bullet-active {
          border-width: 1px;
          &::before {
            width: 4px;
            height: 4px;
          }
        }
      }
    }
  }
  .slider-skincare {
    .sw-absolute-3 {
      top: unset;
      transform: unset;
      bottom: 57px;
    }
  }
  .testimonial-item {
    &.style-row {
      flex-direction: column;
      .image {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .hover-sw-nav {
    .sw-dots {
      display: flex;
    }
    .nav-sw {
      display: none;
    }
  }
  .slider-effect {
    .box-content {
      .heading {
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 14px;
        br {
          display: none;
        }
      }
    }
  }
  .slideshow-effect-zoom {
    .content-left {
      .tf-btn {
        margin-top: 32px;
      }
    }
  }
  .slider-radius {
    .tf-sw-slideshow {
      border-radius: 20px;
      .heading {
        margin-bottom: 20px;
      }
    }
  }
  // option product
  .list-color-product {
    .list-color-item {
      width: 20px;
      height: 20px;
      padding: 2px;
      .swatch-value {
        width: 14px;
        height: 14px;
        border-width: 1px;
      }
    }
  }
  .tf-control-layout {
    .sw-layout-4,
    .sw-layout-5,
    .sw-layout-6 {
      display: none;
    }
  }
  [data-grid="grid-4"],
  [data-grid="grid-5"],
  [data-grid="grid-6"] {
    grid-template-columns: repeat(3, 1fr);
  }
  // .wrap-sidebar-mobile {
  //     display: none;
  // }
  // .btn-sidebar-mobile {
  //     display: block;
  // }
  .discovery-new-item {
    padding: 30px;
  }
  // spacing
  .flat-spacing-4 {
    padding-top: 46px;
    padding-bottom: 85px;
  }
  .flat-spacing-5 {
    padding: 64px 0px;
  }
  .flat-spacing-6 {
    padding: 34px 0px 85px;
  }
  .flat-spacing-7 {
    padding-bottom: 50px;
  }
  .flat-spacing-8 {
    padding-top: 30px;
    padding-bottom: 60px;
  }
  .flat-spacing-9 {
    padding: 55px 0px;
  }
  .flat-spacing-10 {
    padding: 46px 0px;
  }
  .flat-spacing-11 {
    padding-top: 68px;
    padding-bottom: 60px;
  }
  .flat-spacing-12 {
    padding-top: 85px;
    padding-bottom: 85px;
  }
  .flat-spacing-13 {
    padding-top: 28px;
    padding-bottom: 34px;
  }
  .flat-spacing-14 {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .flat-spacing-15 {
    padding-top: 66px;
    padding-bottom: 85px;
  }
  .flat-spacing-16 {
    padding-top: 100px;
    padding-bottom: 55px;
  }
  .flat-spacing-17 {
    padding-top: 85px;
    padding-bottom: 67px;
  }
  .flat-spacing-18 {
    padding-top: 68px;
    padding-bottom: 80px;
  }
  .flat-spacing-23 {
    padding-top: 68px;
    padding-bottom: 80px;
  }
  .flat-spacing-24 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .flat-spacing-27 {
    padding-top: 75px;
    padding-bottom: 75px;
  }
  .flat-spacing-29 {
    padding-top: 22px;
    padding-bottom: 76px;
  }
  .flat-spacing-30 {
    padding-top: 55px;
    padding-bottom: 35px;
  }

  .flat-image-text-section {
    padding-bottom: 0;
  }

  .nav-sw {
    &.lg {
      width: 40px;
      height: 40px;
    }
  }
  .wrap-carousel {
    .nav-next-slider {
      left: -18px;
    }
    .nav-prev-slider {
      right: -18px;
    }
    &.style-2 {
      .nav-next-slider {
        left: -18px;
      }
      .nav-prev-slider {
        right: -18px;
      }
    }
  }

  .wrap-brand-v2 {
    .sw-dots {
      margin-top: 30px;
    }
  }

  .banner-gr-item {
    .content {
      > a {
        margin-top: 14px;
      }
    }
  }
  .wrapper-thumbs-testimonial {
    padding: 0 50px;
    .box-left {
      padding-right: 33px;
      padding-left: 10px;
    }
  }
  .tf-hero-image-liquid {
    .box-content {
      p {
        font-size: 16px;
        line-height: 25.6px;
      }
      .heading {
        font-size: 40px;
        line-height: 48px;
      }
    }
    .tf-countdown {
      .countdown__item {
        .countdown__value {
          font-size: 20px;
          line-height: 24px;
        }
        .countdown__label {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
  }
  .lookbook-sw {
    .navigation-sw-dot {
      width: 30px;
      height: 30px;
      span {
        width: 12px;
        height: 12px;
      }
    }
  }
  .flat-iconbox-v2 {
    .tf-icon-box {
      .title {
        margin-bottom: 8.5px;
      }
    }
  }
  .widget-tab-3 {
    margin-bottom: 30px;
    gap: 30px;

    .nav-tab-item {
      a {
        padding-bottom: 10px;
        font-size: 26px;
        line-height: 31.2px;
      }
    }
  }
  .collection-item-v4 {
    &.style-2 {
      .collection-image {
        height: 450px;
      }
    }
  }
  .masonry-layout-v2 {
    gap: 15px;
    grid-template-areas:
      "item1 item1 item2 item2"
      "item1 item1 item3 item3"
      "item4 item5 item6 item6"
      "item4 item5 item6 item6";
    .item-1,
    .item-6 {
      height: 455px;
    }
    .item-2,
    .item-3 {
      height: 220px;
    }
  }
  .tf-page-cart-footer {
    margin-top: 30px;
  }
  .flat-accordion {
    &.style-default {
      .toggle-content {
        padding: 20px 0;
      }
    }
  }

  #quick_add {
    .modal-content {
      padding: 15px 0px 15px;
      > .wrap {
        padding: 0px 15px;
      }
      .icon-close-popup {
        right: 15px;
      }
    }
    .tf-btn {
      font-size: 14px !important;
    }
  }
  #quick_view {
    .tf-btn {
      font-size: 14px !important;
    }
  }

  .tf-product-btn-wishlist {
    width: 46px;
    height: 46px;
  }
  .tf-content-wrap {
    .heading,
    .description {
      br {
        display: none;
      }
    }
  }
  .description {
    p br {
      display: none;
    }
  }
  .nav-sw {
    &.w_46 {
      width: 40px;
      height: 40px;
    }
  }
  .canvas-sidebar-blog {
    &.canvas-sidebar {
      max-width: 410px;
      width: 100%;
    }
  }
  .masonry-layout-v3 {
    .collection-image {
      height: 415px;
    }
  }
  .col-xl-8 {
    .flat-accordion {
      margin-top: 30px;
    }
  }
  .masonry-layout-v4 {
    &.style-2 {
      gap: 15px;
      .item-1 {
        .collection-image {
          height: 795px;
        }
      }
    }
  }
  .slider-sock {
    .tf-slideshow .wrap-pagination {
      bottom: 20px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .tf-slideshow.about-us-page .text {
    font-size: 50px;
    line-height: 60px;
  }
  .tf-sticky-btn-atc {
    bottom: 67px;
  }
  .tf-toolbar-bottom.type-1024 {
    display: flex;
  }
  .tf-marquee {
    &.type-big {
      .marquee-item {
        p {
          font-size: 60px;
          line-height: 72px;
        }
      }
    }
  }
  .canvas-compare {
    .canvas-body {
      padding: 20px 0;
    }
  }
  .tf-compare-list {
    flex-direction: column;
    max-height: 100%;
    overflow-y: auto;
    gap: 20px;
    > div {
      flex: 0 0 100% !important;
    }
    .tf-compare-offcanvas {
      width: 100%;
      .tf-compare-item {
        flex: 0 0 25%;
      }
    }
    .tf-compare-buttons {
      width: 100%;
      .tf-compare-buttons-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        a {
          max-width: 147px;
        }
      }
    }
  }

  .tf-page-title {
    .heading {
      font-size: 32px;
      line-height: 38px;
    }
  }
  .blog-detail-main-heading {
    .title {
      font-size: 32px !important;
      line-height: 38px !important;
    }
  }
  h1 {
    font-size: 44px;
    line-height: 52.8px;
  }
  h2 {
    font-size: 50px;
    line-height: 60px;
  }
  h4 {
    font-size: 32px;
    line-height: 30.4px;
  }
  .tf-slideshow {
    .card-box {
      .heading {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }
  .tf-countdown-v2 {
    .countdown__timer {
      gap: 10px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .wrap-table-invoice {
    overflow: auto;
  }
  .invoice-table .title th {
    padding: 15px 30px;
  }
  .invoice-table .content td {
    padding: 15px 30px;
  }
  .box-invoice .footer {
    padding: 30px;
  }
  .box-invoice .header {
    padding: 30px;
  }
  .box-invoice .wrap-top,
  .box-invoice .wrap-date,
  .box-invoice .wrap-info {
    gap: 20px;
  }

  .box-invoice .box-left,
  .box-invoice .box-right {
    width: 50%;
  }

  .my-account-nav {
    gap: 10px;
    margin-bottom: 30px;
    .my-account-nav-item {
      font-size: 16px;
      line-height: 28px;
    }
  }
  .my-account-content {
    // padding: 0 30px;
  }
  .tf-slideshow {
    .wrap-pagination {
      bottom: 20px;
    }
  }
  .card-product {
    .list-product-btn {
      .box-icon {
        .tooltip {
          margin-top: 0;
        }
      }
    }
  }
  .collection-item-v2 {
    &.type-small {
      .collection-content {
        .tf-btn {
          padding: 12px 24px;
        }
      }
    }
  }
  .tf-marquee.type-big .marquee-item {
    gap: 50;
    padding: 0 20px;
  }
}
@media only screen and (max-width: 850px) {
  .slider-wrap-lookbook {
    .flat-title-lookbook {
      .heading {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .flat-title-tab {
    display: grid;
    justify-content: center !important;
    .title {
      text-align: center;
    }
  }
  .card-product {
    .count-down {
      display: none;
    }
  }
  .box-video-wrap {
    margin-bottom: 35px;
  }
  .slider-sock {
    .img-mb {
      display: block;
    }
    .img-dk {
      display: none;
    }
    &.tf-slideshow {
      .wrap-slider {
        height: 300px;
      }
    }
  }
  .slider-home-decor {
    .wrap-pagination .sw-dots {
      justify-content: center !important;
    }
  }
  .tf-product-btn-wishlist:hover .tooltip,
  .list-color-product .list-color-item:hover .tooltip {
    display: none;
  }

  .tf-store-list {
    margin-bottom: 15px;
    .tf-store-item.active {
      .tf-store-title {
        margin-bottom: 17px;
      }
      .tf-store-info {
        display: block;
      }
    }
    .tf-store-title {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 0;
    }
    .tf-store-info {
      display: none;
    }
  }
  .tf-accordion-wrap {
    gap: 50px;
    flex-direction: column;
  }
  .tf-slideshow.about-us-page .text {
    font-size: 24px;
    line-height: 29px;
  }
  .tf-product-bundle-image img {
    width: 60px;
    min-width: 60px;
    max-width: 60px;
  }
  .tf-sticky-btn-atc {
    .tf-sticky-atc-product {
      display: none !important;
    }
    .tf-sticky-atc-infos {
      width: 100%;
      form {
        flex-direction: column;
      }
    }
  }
  .flat-iconbox-v3 .tf-icon-box .icon {
    width: 60px;
    height: 60px;
    margin-bottom: 22px;
  }
  .tf-img-video-text {
    .content-wrap {
      .heading {
        font-size: 24px;
        line-height: 29px;
      }
      .number {
        width: 40px !important;
        height: 40px !important;
      }
    }
    video {
      height: 410px;
    }
  }
  .tf-marquee {
    &.type-big {
      .marquee-item {
        svg {
          width: 20px;
        }
        p {
          font-size: 40px;
          line-height: 48px;
        }
      }
    }
  }
  .tf-banner-collection {
    img {
      min-height: 300px;
    }
    .box-content {
      .heading {
        font-size: 22px;
        line-height: 26px;
      }
      p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 15px;
      }
    }
  }
  .flat-lookbook-v3 {
    flex-direction: column-reverse;
    gap: 15px;
    > div {
      width: 100% !important;
    }
    form {
      max-width: 100%;
      text-align: center;
      button {
        width: unset !important;
      }
    }
  }
  .toolbar-shop-mobile {
    .sub-nav-menu {
      margin-left: 17px !important;
    }
    .sub-menu-level-2 {
      margin-left: 34px !important;
    }
  }
  .form-sign-in {
    .modal-dialog {
      margin-top: 8px;
      margin-bottom: 8px;
      height: calc(100vh - 16px);
      max-height: calc(100vh - 16px);
      .modal-content {
        padding: 20px;
        .bottom {
          gap: 15px;
        }
      }
    }
  }
  .canvas-search {
    max-width: 320px;
    .tf-search-head {
      .title {
        font-size: 18px;
      }
    }
    .tf-search-content-title {
      font-size: 16px;
      line-height: 19px;
    }
  }
  .modal-shopping-cart {
    .modal-content {
      max-width: 90% !important;
    }
    .tf-mini-cart-threshold {
      padding: 10px 0;
    }
    .tf-mini-cart-tool {
      .tf-mini-cart-tool-btn {
        width: 50px;
        height: 38px;
        line-height: 38px;
        margin: 18px 8px;
        font-size: 18px;
      }
    }
    .tf-mini-cart-bottom-wrap .tf-mini-cart-view-checkout a {
      height: 39px;
    }
    .tf-mini-cart-threshold .tf-progress-msg {
      font-size: 13px;
      line-height: 20.8px;
    }
    .tf-mini-cart-item {
      gap: 14px;
    }
  }
  #quick_view {
    .wrap {
      flex-direction: column;
      overflow-y: auto;
      .tf-product-media-wrap {
        width: 100% !important;
        padding-right: 0 !important;
      }
      .tf-product-info-wrap {
        .tf-product-info-list {
          overflow-y: unset;
          position: unset;
          padding: 20px 15px;
        }
      }
    }
  }
  .tf-compare-list {
    .tf-compare-head {
      .title {
        font-size: 24px;
      }
    }
    .tf-compare-offcanvas {
      .tf-compare-item {
        flex: 0 0 33.333%;
        padding: 0 8px;
      }
    }
  }
  .modal-part-content {
    .modal-dialog {
      min-width: unset;
      align-items: flex-end;
      .header {
        margin-bottom: 22px;
      }
    }
  }
  .tf-compare-color-item {
    min-width: 180px;
    max-width: 180px;
    padding: 10px 10px 12px;
    form {
      a {
        height: 41px;
      }
    }
  }
  .modal-dialog {
    max-width: 500px !important;
    .demo-title {
      font-size: 18px !important;
      font-weight: 400 !important;
      line-height: 22px !important;
    }
  }
  #ask_question {
    button {
      font-size: 14px !important;
      height: 39px;
    }
  }
  #find_size {
    .modal-dialog {
      margin-top: 8px !important;
      margin-bottom: 8px !important;
    }
    .tf-page-size-chart-content {
      grid-template-columns: unset;
      > div:last-child {
        text-align: center;
      }
    }
  }
  [qol-data-title]:before {
    content: attr(qol-data-title);
    color: var(--main);
    text-align: start;
    flex: 1 1 auto;
  }
  .tf-quick-order-list-total {
    bottom: 67px;
    .tf-total-wrap {
      flex-direction: column;
      gap: 5px;
      > div {
        width: 100%;
      }
      .tf-total-item-inner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        font-size: 18px;
        line-height: 29px;
      }
      .tf-viewcart {
        order: 999;
        margin-top: 10px;
      }
      .tf-total-price {
        text-align: start;
        .tf-totals-variant-total {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
  .tf-variant-item {
    display: block;
    position: relative;
    padding-left: 90px;
    margin-bottom: 15px;
    > * {
      width: 100%;
      display: flex;
      padding: 10px 0;
      &:first-child {
        padding: 0;
      }
    }
    .tf-variant-item-image-container {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .tf-qol-head {
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
  .blog-article-item {
    &.style-row {
      flex-direction: column;
      gap: 15px;
      .article-thumb {
        width: 100%;
        height: unset;
      }
      .article-content {
        gap: 10px;
      }
      .article-title {
        a {
          font-size: 16px;
          line-height: 19px;
        }
      }
      .article-btn {
        margin-top: 10px;
      }
    }
  }
  .tf-product-fbt-wrap {
    form {
      .tf-product-fbt-list {
        img {
          width: 90px;
          max-width: 90px;
          height: unset;
        }
      }
      .tf-fbt-col-total-price {
        width: 100%;
      }
    }
  }
  .tf-product-media-wrap {
    margin-bottom: 15px;
  }
  .tf-product-info-countdown {
    .countdown-wrap {
      width: 100%;
    }
  }
  // .tf-product-info-buy-button {
  //     .tf-product-btn-wishlist {
  //         order: 1;
  //     }
  // }
  h1 {
    font-size: 34px;
    line-height: 40.8px;
  }
  h2 {
    font-size: 32px;
    line-height: 38.4px;
  }
  h4 {
    font-size: 22px;
    line-height: 26.4px;
  }
  h5 {
    font-size: 18px;
    line-height: 21.6px;
  }
  h6 {
    font-size: 16px;
    line-height: 26px;
  }
  .footer {
    .footer-heading-desktop {
      display: none;
    }
    .footer-col-block {
      .footer-heading-moblie {
        margin-top: 30px;
        margin-bottom: 15px;
        display: block;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          width: 12px;
          height: 1px;
          background-color: var(--main);
          transition: 0.25s ease-in-out;
        }
        &::before {
          position: absolute;
          content: "";
          right: 15px;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 1px;
          height: 12px;
          background-color: var(--main);
          transition: 0.25s ease-in-out;
        }
      }
      &.open {
        .footer-heading-moblie {
          &::before {
            opacity: 0;
          }
          &::after {
            transform: translate(0%, -50%) rotate(180deg);
          }
        }
        .tf-collapse-content {
          max-height: 350px;
        }
      }
    }
    .tf-collapse-content {
      max-height: 0px;
      overflow: hidden;
      transition: 0.4s;
    }
    .footer-bottom {
      .footer-bottom-wrap {
        flex-wrap: wrap;
        > div {
          width: 100%;
          text-align: center;
          justify-content: center;
        }
      }
    }
    &.has-all-border,
    &.has-border {
      .footer-col,
      .footer-newsletter {
        width: 100% !important;
      }
    }
    &.background-black {
      .footer-heading-moblie {
        &::after,
        &::before {
          background-color: var(--white);
        }
      }
    }
  }
  #header {
    .nav-icon {
      .nav-account,
      .nav-wishlist,
      .nav-compare {
        display: none;
      }
    }
  }
  .mega-menu .row-demo {
    grid-template-columns: 1fr;
  }
  .box-sw-announcement-bar {
    animation: slide-har 4s linear infinite;
  }
  .speed-1 {
    animation: slide-har 15s linear infinite !important;
  }
  .tf-slideshow {
    .wrap-slider {
      height: 450px;
    }
    .banner-wrapper {
      height: 500px;
    }
    &.flat-banner-sock {
      .wrap-slider {
        height: 300px;
      }
    }
    &.slider-grocery {
      .wrap-slider {
        height: 350px;
      }
    }
  }
  .slider-home-2 {
    .wrap-slider {
      height: 300px;
      margin-right: -80px;
    }
  }
  .slider-home-4 {
    .wrap-slider {
      height: 250px;
    }
  }
  .slider-women {
    .wrap-slider {
      height: 400px;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 700px;
        min-width: max(100%, 700px);
        // right: 100px;
        // width: 2000px;
        // min-width: 700px;
        // width: 2000px;
        // width: unset;
        // height: 100%;
        // object-fit: unset;
      }
    }
  }
  .slider-baby {
    .wrap-slider {
      height: 300px;
    }
  }
  .slider-radius {
    .wrap-slider {
      height: 350px;
    }
  }
  .tf-hero-image-liquid {
    height: 350px;
    .box-content {
      text-align: center;
      .heading {
        font-size: 24px;
        line-height: 28.8px;
      }
    }
    .tf-countdown {
      .countdown__timer {
        justify-content: center;
        gap: 10px;
      }
      .countdown__item {
        width: 60px;
        height: 60px;
      }
    }
  }
  .banner-countdown-v2 {
    height: 250px;
  }
  .tf-btn {
    &.btn-xl {
      font-size: 16px;
      line-height: 40px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .wrap-slider {
    .box-content {
      p {
        display: none;
      }
    }
    .card-box {
      .tf-btn {
        font-size: 14px;
        line-height: 38px;
      }
      p {
        display: block;
      }
    }
  }
  .sw-dots {
    gap: 4px;
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
    }
  }
  .md-hidden {
    display: none;
  }
  .slider-home-5 {
    .box-content {
      p {
        display: block;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
      }
    }
  }
  .slider-giftcard {
    .box-content {
      padding-right: 40px;
      .heading {
        br {
          display: none;
        }
      }
      p {
        display: block;
        font-size: 16px;
        line-height: 19.2px;
        margin-bottom: 24px;
      }
    }
  }
  .tf-page-title {
    padding: 52px 0 49px;
    .heading {
      font-size: 22px;
      line-height: 26px;
    }
  }
  .blog-detail-main-heading {
    .title {
      font-size: 22px !important;
      line-height: 26px !important;
    }
  }
  .wrap-slider {
    .card-box {
      margin: auto;
      p {
        display: none;
      }
      .heading {
        font-size: 24px;
        line-height: 28.8px;
        margin-bottom: 24px;
      }
    }
  }
  .slider-effect {
    display: flex;
    flex-direction: column-reverse;
    .box-content {
      .heading {
        font-size: 34px;
        line-height: 40.8px;
      }
      .desc {
        display: block;
        margin-bottom: 22px;
        font-size: 16px;
        line-height: 19.2px;
      }
    }
    .content-left {
      position: unset;
      padding: 40px 0px;
      text-align: center;
    }
    .img-slider {
      width: 100%;
      height: 300px;
    }
  }
  .slideshow-effect {
    .wrap-pagination {
      bottom: 15px;
      .sw-dots {
        justify-content: center;
      }
    }
    &.style-padding {
      .content-left {
        padding-bottom: 60px;
      }
    }
  }
  .slideshow-effect-zoom {
    padding: 0;
    .content-left {
      padding: 14px;
      padding-bottom: 24px;
      .desc {
        margin-top: 16px;
        font-size: 18px;
        line-height: 28.8px;
      }
      .tf-btn {
        margin-top: 18px;
      }
    }
    .wrap-content {
      flex-direction: column-reverse;
      gap: 15px;
      .content-left,
      .content-right {
        width: 100%;
      }
      .content-left {
        text-align: center;
        .heading {
          br {
            display: none;
          }
        }
      }
    }
  }
  .slider-radius {
    padding: 0;

    .tf-sw-slideshow {
      border-radius: 0px;
      .subheading {
        display: block;
      }
      .heading {
        margin-bottom: 20px;
      }
    }
  }
  // option product
  .card-product {
    .countdown-box,
    .size-list,
    .wishlist,
    .compare {
      display: none;
    }
    .card-product-wrapper {
      .list-product-btn {
        bottom: 5px;
        left: 5px;
        right: 5px;
        &.absolute-2 {
          bottom: 5px;
        }
        &.absolute-3 {
          bottom: 5px;
        }
      }
      .column-right {
        top: 5px;
        right: 5px;
      }
      .column-left {
        top: 5px;
        left: 5px;
      }
    }
    &.style-6,
    &.style-7 {
      .list-product-btn {
        justify-content: flex-start;
      }
    }
  }
  .tf-control-layout {
    .sw-layout-3 {
      display: none;
    }
  }
  [data-grid="grid-2"],
  [data-grid="grid-3"],
  [data-grid="grid-4"],
  [data-grid="grid-5"],
  [data-grid="grid-6"] {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .flat-title-v2 {
    margin-bottom: 30px;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .nav-sw {
    width: 24px;
    height: 24px;
  }
  .discovery-new-item {
    height: auto;
    margin-top: 12px;
    padding: 10px 15px;
    gap: 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h5 {
      font-size: 22px;
      line-height: 26.4px;
    }
    a {
      width: 40px;
      height: 40px;
    }
  }
  .flat-spacing-1 {
    padding: 52px 0px;
  }
  .flat-spacing-3 {
    padding: 30px 0;
  }
  .flat-spacing-4 {
    padding-top: 33px;
    padding-bottom: 60px;
  }
  .flat-spacing-5 {
    padding: 38px 0px;
  }
  .flat-spacing-6 {
    padding: 30px 0px 70px;
  }
  .flat-spacing-7 {
    padding-bottom: 45px;
  }
  .flat-spacing-8 {
    padding-top: 15px;
    padding-bottom: 35px;
  }
  .flat-spacing-9 {
    padding: 48px 0px;
  }
  .flat-spacing-10 {
    padding: 40px 0px;
  }
  .flat-spacing-11 {
    padding-top: 35px;
    padding-bottom: 22px;
  }
  .flat-spacing-12 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .flat-spacing-13 {
    padding-top: 25px;
    padding-bottom: 30px;
  }
  .flat-spacing-14 {
    padding-top: 42px;
    padding-bottom: 30px;
  }
  .flat-spacing-15 {
    padding-top: 40px;
    padding-bottom: 50px;
  }
  .flat-spacing-16 {
    padding-top: 65px;
    padding-bottom: 32px;
  }
  .flat-spacing-17 {
    padding-top: 30px;
    padding-bottom: 23px;
  }
  .flat-spacing-18 {
    padding-top: 26px;
    padding-bottom: 30px;
  }
  .flat-spacing-19 {
    padding: 50px 0;
  }
  .flat-spacing-21 {
    padding-top: 28px;
    padding-bottom: 35px;
  }
  .flat-spacing-27 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .flat-spacing-29 {
    padding-top: 19px;
    padding-bottom: 67px;
  }
  .flat-spacing-30 {
    padding-top: 48px;
    padding-bottom: 50px;
  }
  .lookbook-item {
    .tf-pin-btn {
      width: 20px;
      height: 20px;
      span {
        width: 6px;
        height: 6px;
      }
    }
  }
  .wrap-carousel {
    .nav-sw {
      display: none;
    }
    .sw-dots {
      display: flex;
      margin-top: 15px;
    }
  }

  .tf-img-with-text {
    .tf-content-wrap {
      text-align: center;
    }
  }
  .tf-btn {
    &.style-3,
    &.style-2 {
      line-height: 38px;
    }
    &.btn-md {
      padding: 0px 24px;
    }
  }
  .banner-gr-item {
    .img-style {
      margin-bottom: 15px;
    }
    .content {
      .title {
        font-size: 20px;
        line-height: 24px;
      }
      > a {
        margin-top: 10px;
      }
    }
  }
  .md-pb-70 {
    padding-bottom: 70px;
  }
  .wrapper-thumbs-testimonial {
    padding: 0;
    .box-left {
      display: none;
    }
    .box-right {
      width: 100%;
    }
  }
  .tf-sw-testimonial.mb_60 {
    margin-bottom: 15px;
  }
  .testimonial-item {
    .box-author {
      .content {
        margin-top: 5px;
      }
    }
    &.style-box {
      padding: 15px 24px;
    }
  }
  .wrapper-thumbs-testimonial-v2 {
    .box-right {
      display: none;
    }
    .box-left {
      width: 100% !important;
      padding: 0;
    }
  }
  .tf-icon-box {
    &.style-row {
      flex-direction: column;
      text-align: center;
    }
  }
  .slider-wrap-lookbook {
    .nav-sw {
      display: none;
    }
    .sw-dots {
      justify-content: center;
    }
    .card-product {
      display: flex;
      gap: 10px;
      .card-product-wrapper {
        max-width: 100px;
      }
      .card-product-info {
        place-self: flex-start;
        padding-top: 0px;
      }
    }
  }
  .lookbook-sw {
    .navigation-sw-dot {
      width: 24px;
      height: 24px;
      span {
        width: 8px;
        height: 8px;
      }
      &.swiper-button-disabled {
        width: 30px;
        height: 30px;
      }
    }
  }
  .flat-iconbox-v2 {
    .tf-icon-box {
      .title {
        margin-bottom: 6.5px;
      }
    }
  }
  .widget-tab-3 {
    .nav-tab-item {
      a {
        padding-bottom: 5px;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
  .collection-item-v4 {
    &.style-2 {
      .collection-image {
        height: 300px;
      }
    }
  }
  .masonry-layout-v2 {
    gap: 15px;
    grid-template-areas:
      "item1 item1"
      "item2 item3"
      "item4 item5"
      "item6 item6";
    .item-1,
    .item-6 {
      height: 455px;
    }
    .item-2,
    .item-3,
    .item-4,
    .item-5 {
      height: 200px;
    }
  }
  .tf-table-page-cart {
    thead {
      display: none;
    }
  }
  .tf-cart-item td[cart-data-title]:before {
    content: attr(cart-data-title);
    color: var(--main);
    text-align: start;
    flex: 1 1 auto;
  }
  .tf-cart-item {
    margin-bottom: 15px;
    padding-inline-start: 98px;
    min-height: 140px;
    display: block;
    font-size: 12px;
    font-weight: 600;
    position: relative;
    td {
      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 10px 0;
      margin: 0;
      &:not(:last-child) {
        border-bottom: 1px dashed var(--line);
      }
    }
    .img-box {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
  .tf-page-cart-checkout {
    padding: 30px 15px;
    .tf-btn {
      height: 38px;
    }
  }
  .lookbook-product {
    gap: 10px;
    width: 260px;
  }
  .tf-img-with-text.style-3 {
    .tf-content-wrap {
      order: 2;
    }
    .tf-image-wrap {
      order: 1;
    }
  }
  .btn-md {
    min-height: 40px;
  }
  .masonry-layout-v3 {
    .collection-image {
      height: 350px;
    }
  }
  .masonry-layout-v4 {
    .collection-inner {
      height: 315px;
    }
  }
  .tf-btn {
    &:not(.btn-xl, .btn-md, .btn-line, .style-2, .style-3) {
      padding: 10px 24px;
    }
    &.style-2 {
      line-height: 36px;
    }
  }
  .lookbook-product {
    .content-wrap {
      .product-title {
        a {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .price {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .image {
      width: 52px;
      height: 60px;
    }
    > a {
      width: 30px;
      height: 30px;
      font-size: 12px;
    }
  }
  .wrap-mobile {
    .collection-item-v2 {
      .collection-image {
        height: 300px;
      }
    }
  }
  .form-checkout {
    .grid-2 {
      grid-template-columns: 1fr;
    }
  }
  .coupon-box {
    input,
    .tf-btn {
      height: 40px;
    }
  }
  .sw-dots {
    &.style-2,
    &.medium {
      span {
        width: 16px;
        height: 16px;
        border-width: 1px !important;
      }
    }
  }
  .flat-control-sw {
    position: relative;
    .box-sw-navigation {
      position: absolute;
      bottom: 15px;
      left: 15px;
      right: 15px;
    }
  }
  .grid-3-layout-md {
    .collection-line-upper {
      .collection-image {
        height: 313px;
      }
    }
  }
  .masonry-layout-v4 {
    &.style-2 {
      .item-1,
      .item-2,
      .item-3 {
        .collection-image {
          height: 250px;
        }
      }
    }
  }
  .text-22 {
    font-size: 18px !important;
  }
  .wrap-slider .box-content p {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 19.2px;
  }
}

@media only screen and (max-width: 575px) {
  .box-invoice .wrap-top,
  .box-invoice .wrap-date,
  .box-invoice .wrap-info {
    flex-wrap: wrap;
  }

  .box-invoice .box-left,
  .box-invoice .box-right {
    width: 100%;
  }

  .tf-compare-list {
    .tf-compare-offcanvas {
      .tf-compare-item {
        flex: 0 0 50%;
      }
    }
  }
  .card-product {
    .list-product-btn {
      .box-icon {
        .tooltip {
          display: none;
        }
      }
    }
    &:not(.style-3) {
      .list-product-btn {
        .box-icon {
          .text {
            display: none;
          }
          &.style-2 {
            width: 32px;
          }
        }
      }
    }

    .list-color-product {
      overflow: hidden;
    }
  }
  .tf-dropdown-sort .dropdown-menu {
    min-width: 150px;
  }
  .lookbook-1 {
    .item-1 {
      top: 78%;
      left: 57%;
    }
    .item-2 {
      top: 58%;
      left: 40%;
    }
  }
  .lookbook-2 .item-1 {
    top: 20%;
    left: 57%;
  }
  .widget-tab-3 {
    &.style-2 {
      justify-content: flex-start !important;
    }
  }
  .lookbook-kid {
    .item-1 {
      left: 35%;
    }
    .item-2 {
      left: 35%;
    }
    .item-3 {
      left: 56%;
    }
  }
  .banner-countdown-v2 {
    .box-content {
      text-align: left;
      left: 10%;
      right: 10%;
    }
    .tf-countdown {
      .countdown__timer {
        justify-content: flex-start;
      }
    }
  }
}
