.tf-lookbook {
    display: flex;
}

.wrap-lookbook {
    width: 100%;
    position: relative;
    .image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &.lookbook-sw{
        .navigation-sw-dot {
            position: absolute;
        }
        .nav-next-lookbook{
            top: 5%;
            left: 19%;
        }
        .nav-prev-lookbook{
            top: 52%;
            left: 30%;
        }
    }
}

.lookbook-item {
    position: absolute;
    width: 26px;
    height: 26px;
    .tf-pin-btn {
        width: 26px;
        height: 26px;
        background-color: rgb(242, 242, 242);
        border-radius: 50%;
        border: 0;
        outline: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--main);
        }
        &.pin-white{
            background-color: var(--white);
        }
        &.pin-black{
            background-color: var(--main);
            span{
            background-color: var(--white);

            }
        }
    }
    .dropdown-menu {
        margin: 20px 0 !important;
        top: unset !important;
        bottom: 23px !important;
        transform: translateX(-46%) !important;
    }
    
}

.lookbook-product {
    padding: 10px 14px 12px 11px;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    width: 334px;
    background-color: var(--white);
    box-shadow: 0 0 15px #0000001a;
    -webkit-transition: all 0.2s ease;
    -moz-transition   : all 0.2s ease;
    -ms-transition    : all 0.2s ease;
    -o-transition     : all 0.2s ease;
    transition        : all 0.2s ease;
    .image {
        width: 62px;
        height: 70px;
        flex-shrink: 0;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .content-wrap {
        flex-grow: 1;
        .product-title {
            margin-bottom: 6px;
            a {
                font-size: 16px;
                font-weight: 400;
                line-height: 26px;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                overflow: hidden;
                &:hover {
                    color: var(--primary);
                }
            }
        }
        .price {
            font-weight: 600;
            color: var(--main);
        }
    }
    > a {
        width: 38px;
        height: 38px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: var(--line);
        color: var(--main);
        font-size: 14px;
        &:hover {
            background-color: var(--main);
            color: var(--white);
        }
    }
    &::after {
        position: absolute;
        content: '';
        width: 16px;
        height: 16px;
        transform: translate(-50%,50%) rotate(45deg);
        background-color: var(--white);
        bottom: 0;
        left: 50%;
        z-index: 2;
    }
}

.tf-pin_btn:hover:after, .tf-pin_btn:hover:before {
    animation: ripple 3s infinite;
}



.lookbook-1 {
    .item-1 {
        top: 85%;
        left: 62%;
    }
    .item-2 {
        top: 63%;
        left: 45%;
    }
}
.lookbook-2 {
    .item-1 {
        top: 20%;
        left: 62%;
    }
}

.tf-sw-lookbook{
    padding-top: 70px;
    margin-top: -70px; 
    .wrap-pagination{
        position: absolute;
        bottom: 10px;
        z-index: 10;
        left: 15px;
        right: 15px;
        .sw-dots{
            margin: 0;
        }
    }
}

.lookbook-kid{
    position: relative;
    .lookbook-item{
        position: absolute;
        .tf-pin-btn{
            background-color: var(--white);
        }
    }
    .item-1{
        left: 24%;
        top: 32%;
    }
    .item-2{
        left: 24%;
        top: 78%;
    }
    .item-3{
        left: 50%;
        top: 70%;
    }
}

.lookbook-dog{
    position: relative;
    .lookbook-item{
        position: absolute;
        .tf-pin-btn{
            background-color: var(--white);
        }
    }
    .item-1{
        left: 37%;
        top: 32%;
    }
    .item-2{
        left: 53%;
        top: 70%;
    }
    .item-3{
        left: 64%;
        top: 25%;
    }
}


.slideshow-lookbook-furniture{
    .lookbook-1{
        .item-1{
            left: 40%;
            top: 40%;
        }
        .item-2{
            left: 50%;
            top: 50%;
        }
    }
    .lookbook-2{
        .item-1{
            left: 50%;
            top: 50%;
        }
    }
    .lookbook-3{
        .item-1{
            top: 45%;
            left: 45%;
        }

    }
}

.lookbook-kitchen-wear {
    form {
        .tf-lookbook {
            height: unset !important;
        }
        .swiper-slide:last-child {
            .tf-bundle-product-item {
                border: 0;
            }
        }
        .tf-btn {
            width: 100%;
            max-width: 461px;
        }
    }
    .wrap-lookbook {
        .item-1 {
            top: 65%;
            left: 50%;
        }
        .item-2 {
            top: 17%;
            left: 57%;
        }
        .item-3 {
            top: 70%;
            left: 85%;
        }
    }
}

.flat-activewear-lookbook{
    .wrap-lookbook{
        .item-1{
            top: 20%;
            left: 65%;
        }
        .item-2{
            top: 60%;
            left: 16%;
        }
        .item-3{
            top: 61%;
            left: 80%;
        }
    }
    .inner-sw-lookbook{
        .tf-btn{
            margin-top: 16px;
            border-radius: 60px;

        }
    }
}

.lookbook-handbag{
    .lookbook-item{
        position: absolute;
    }
    .item-1{
        left: 49%;
        top: 28%;
    }
    .item-2{
        left: 30%;
        top: 45%;
    }
    .item-3{
        left: 50%;
        top: 68%;
    }
}

.lookbook-sneaker{
    .lookbook-item{
        position: absolute;
    }
    .item-1{
        left: 64%;
        top: 21%;
    }
    .item-2{
        left: 38%;
        top: 45%;
    }
}