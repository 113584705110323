.offcanvas {
  z-index: 3000;
  border: none !important;
}

.offcanvas-backdrop {
  background-color: var(--backdrop);
  cursor: url("/images/item/cursor-close.svg"), auto;

  &.show {
    opacity: 1;
  }
}

.overflow-x-auto,
.overflow-y-auto {
  &::-webkit-scrollbar {
    width: 0px;
  }
}

// modal
.modal-backdrop {
  background-color: var(--backdrop);
  &.show {
    opacity: 1;
  }
  // &:nth-child(1),
  // &:nth-of-type(1) {
  //     z-index: 1050 !important;
  // }
}
// .fade.show:last-child {
//     z-index: 1061 !important;
// }
.modal {
  cursor: url("/images/item/cursor-close.svg"), auto;
  &.show {
    .modal-dialog {
      transform: none;
      transition: transform 0.3s ease-out !important;
    }
  }
  // Right
  &.fullRight {
    .modal-dialog {
      transform: translate(100%, 0);
      min-width: 100%;
      height: 100%;
      margin: 0;
      transition: transform 1s ease-out;
      .modal-content {
        border-radius: 0;
        border: 0;
        margin: auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        padding: 0;
        .modal-body {
          overflow: auto;
          padding: 0;
          padding-bottom: 30px;
        }
      }
    }
    &.show {
      .modal-dialog {
        transform: translate(0, 0);
      }
    }
  }
  // Left
  &.fullLeft {
    .modal-dialog {
      transform: translate(-100%, 0) !important;
      min-width: 100%;
      height: 100%;
      margin: 0;
      transition: all 0.3s !important;
      .modal-content {
        border-radius: 0;
        border: 0;
        margin: auto;
        overflow: hidden;
        position: absolute;
        left: 0;
        bottom: 0;
        top: 0;
        padding: 0;
        .modal-body {
          overflow: auto;
          padding: 0;
          padding-bottom: 30px;
        }
      }
    }
    &.show {
      .modal-dialog {
        transform: translate(0, 0) !important;
      }
    }
  }
  // modalLeft
  &.modalLeft {
    .modal-dialog {
      transform: translate(-100px, 0);
    }
  }
  // modalRight
  &.modalRight {
    .modal-dialog {
      transform: translate(100px, 0);
    }
  }
  // modalBottom
  &.modalBottom {
    .modal-dialog {
      transform: translate(0, 100px);
    }
  }
  // modalCentered
  &.modalCentered {
    .modal-dialog {
      transform: translate(0, 0) !important;
    }
  }
  .modal-content {
    cursor: default !important;
  }
  &.fade {
  }
  &.fade:not(.show) {
    opacity: 0;
    // transition: opacity 3s linear;
  }
}
.modalDemo {
  .demo-title {
    margin-top: 50px;
    margin-bottom: 44px;
    font-weight: 500;
    text-align: center;
  }
  .modal-dialog {
    max-width: 1540px;
    margin-top: 8px;
    margin-bottom: 8px;
    height: calc(100vh - 16px);
  }
  .modal-content {
    padding: 0 50px 40px;
    background-color: var(--white);
    width: 100%;
    border-radius: 10px;
    margin: 0 30px;
    max-height: calc(100vh - 60px);
    border: 0;
    cursor: default;
    overflow: hidden;
  }
  .mega-menu {
    padding: 0 40px;
    overscroll-behavior-y: contain;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--line);
    }
  }
  .header {
    position: relative;
    .icon-close-popup {
      position: absolute;
      top: 18px;
      right: 0;
      width: 30px;
      height: 30px;
      display: inline-flex;
      justify-content: flex-end;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: rgb(134 134 134);
    }
  }
}

.tf-product-modal {
  .modal-dialog {
    // max-height: calc(100svh - 16px);
    max-width: min(625px, 90vw);
    .modal-content {
      padding: 38px 36px 40px;
      margin-left: 0;
      margin-right: 0;
      .header {
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .demo-title {
          margin: 0;
          text-align: start;
          font-size: 26px;
          font-weight: 400;
          line-height: 31px;
        }
        span {
          position: unset;
          color: var(--main);
          @include transition3;
          &:hover {
            color: var(--primary);
          }
        }
      }
      h6 {
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 25px;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        margin-top: 15px;
      }
      p {
        margin-top: 15px;
        margin-bottom: 20px;
      }
    }
  }
  .tf-social-icon {
    .box-icon {
      width: 40px;
      height: 40px;
      border-radius: 999px;
      font-size: 16px;
      color: var(--white);
      &.social-twiter {
        font-size: 12px;
        background: var(--twitter-cl);
      }
      &.social-facebook {
        background: var(--facebook-cl);
      }
      &.social-instagram {
        background: var(--instagram-cl);
      }
      &.social-tiktok {
        background: var(--tiktok-cl);
      }
      &.social-pinterest {
        background: var(--pinterest-cl);
      }
    }
  }
  .form-share {
    margin-top: 20px;
    position: relative;
    .button-submit {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      .tf-btn {
        padding: 10px 18px;
      }
    }
    input {
      padding-right: 80px;
    }
  }
}

#find_size {
  // z-index: 1062 !important;
  .modal-dialog {
    max-width: min(900px, 90vw);
    .modal-content {
      // max-height: calc(100svh - 16px);
    }
  }
  .tf-rte {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  .tf-sizeguide-table {
    border: 1px solid var(--line);
    border-radius: 5px;
    width: 100%;
    th {
      border: 1px solid var(--line);
      padding: 10px;
      font-weight: 600;
      line-height: 20px;
    }
    td {
      border: 1px solid var(--line);
      border-width: 0 1px 1px 0;
      padding: 10px;
      line-height: 20px;
    }
  }
  .tf-page-size-chart-content {
    margin-top: 15px;
    display: grid;
    gap: 10px;
    grid-template-columns: 8fr 4fr;
  }
  p {
    color: var(--text-3);
  }
}

#compare_color {
  .modal-dialog {
    max-width: min(1400px, 90vw);
    min-width: min(1400px, 90vw);
    margin-top: 8px;
    margin-bottom: 8px;
    height: calc(100vh - 16px);
    max-height: calc(100vh - 16px);
    .modal-content {
      padding: 30px 28px 33px;
    }
    .header {
      justify-content: center;
      margin-bottom: 32px;
      .icon-close-popup {
        position: absolute;
        top: 0;
      }
    }
  }
  .tf-compare-color-wrapp {
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
      height: 3px;
    }
  }
  .tf-compare-color-grid {
    display: inline-flex;
    text-align: center;
    border: 1px solid var(--line);
    > input {
      position: absolute !important;
      overflow: hidden;
      width: 1px;
      height: 1px;
      margin: -1px;
      padding: 0;
      border: 0;
      clip: rect(0 0 0 0);
      word-wrap: normal !important;
      &:checked + .tf-compare-color-item {
        display: none !important;
      }
    }
  }
}
.tf-compare-color-item {
  display: flex;
  flex-direction: column;
  padding: 14px 22px 28px;
  min-width: 330px;
  max-width: 330px;
  flex: 1;
  &:not(:last-child) {
    border-right: 1px solid var(--line);
  }
  .tf-compare-color-top {
    label {
      text-decoration: underline;
      color: var(--main);
      font-size: 12px;
      font-weight: 400;
      line-height: 19px;
      @include transition3;
      cursor: pointer;
    }
    img {
      border-radius: 10px;
      margin-top: 14px;
    }
  }
  .tf-compare-color-bottom {
    margin-top: 1.5px;
    .tf-compare-color-color {
      display: inline-flex;
      align-items: center;
      gap: 7px;
      min-width: 87px;
      margin-top: 14px;
      padding: 3px 10px 3px 4px;
      border: 1px solid var(--line);
      border-radius: 24px;
      font-weight: 600;
      .tf-color-list-color {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba(134, 134, 134, 0.12);
      }
    }
    form {
      select {
        margin-top: 14px;
        width: 100%;
      }
      a {
        width: 100%;
        margin-top: 15px;
        border-radius: 3px;
      }
    }
  }
}

#ask_question {
  fieldset {
    label {
      margin-bottom: 5px;
      font-weight: 400;
      color: var(--text);
    }
    input {
      height: 50px;
    }
    margin-bottom: 15px;
  }
  textarea {
    height: 176px;
  }
}

#delivery_return {
  .tf-product-popup-delivery {
    .title {
      font-size: 20px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 25px;
      margin-top: 0;
    }
    p {
      color: rgb(134, 134, 134);
      line-height: 22.4px;
      margin-bottom: 10px;
      a {
        color: rgb(134, 134, 134);
        text-decoration: underline;
        text-underline-offset: 3px;
        &:hover {
          color: var(--main);
          text-decoration-thickness: 2px;
        }
      }
    }
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
}

#quick_add {
  .modal-dialog {
    max-width: min(466px, 90vw);
  }
  .modal-content {
    margin: 8px;
    padding: 30px 0px 30px;
    > .wrap {
      overflow-y: auto;
      padding: 0px 20px;
      &::-webkit-scrollbar {
        width: 2px;
      }
    }
    .icon-close-popup {
      top: 0px;
      right: 20px;
    }
  }
  .tf-product-info-item {
    margin-bottom: 15px;
    display: flex;
    gap: 18px;
    align-items: center;
    .image {
      img {
        width: 70px;
        height: 98px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 6px;
      a {
        font-size: 20px;
        line-height: 24px;
      }
      .price {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }
  .payment-more-option {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

#quick_view {
  .modal-dialog {
    max-width: min(1146px, 90vw);
    .modal-content {
      margin: 8px;
      padding: 0;
      .icon-close-popup {
        top: 20px;
        right: 20px;
        font-size: 16px;
        z-index: 5;
      }
      > .wrap {
        display: flex;
      }
    }
  }
  .tf-product-media-wrap {
    flex: 0 0 auto;
    max-width: 100%;
    // width: 50%;
    width: min((50%), 68vh) !important;
  }
  .tf-product-info-wrap {
    flex: 0 0 auto;
    max-width: 100%;
    flex-grow: 1;
    // width: 50%;
    // width: max(50%, calc(min(113rem, 90vw) - 68vh)) !important;
    .tf-product-info-list {
      position: absolute;
      inset: 0;
      padding: 50px 40px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
      .tf-product-info-title {
        margin-bottom: 20px;
      }
      .tf-product-info-badges {
        margin-bottom: 18px;
      }
      .tf-product-description {
        color: var(--text-3);
      }
    }
  }
}

.bg-color-beige {
  background: conic-gradient(#c8ad7f 0deg 360deg);
}
.bg-color-black {
  background: conic-gradient(#000000 0deg 360deg);
}
.bg-color-blue {
  background: conic-gradient(#a8bcd4 0deg 360deg);
}
.bg-color-white {
  background: conic-gradient(#ffffff 0deg 360deg);
}
.bg-color-pink {
  background: conic-gradient(#fcc6de 0deg 360deg);
}
.bg-color-brown {
  background: conic-gradient(#977945 0deg 360deg);
}
.bg-color-light-purple {
  background: conic-gradient(#d966d9 0deg 360deg);
}
.bg-color-light-green {
  background: conic-gradient(#caffd6 0deg 360deg);
}
.bg-color-orange {
  background: conic-gradient(#ffa500 0deg 360deg);
}
.bg-color-light-blue {
  background: conic-gradient(#add8e6 0deg 360deg);
}

// Filter Shop

.canvas-wrapper {
  padding: 0;
  isolation: isolate;
  height: 100%;
  width: 100%;
  max-height: none;
  display: grid;
  grid-auto-rows: auto minmax(0, 1fr) auto;
  align-content: flex-start;
}
.canvas-body {
  background-color: var(--white);
  padding: 15px 20px;
  overscroll-behavior-y: contain;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: var(--bg-scrollbar-track);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--bg-scrollbar-thumb);
    border-radius: 4px;
  }
}

.canvas-filter {
  max-width: 300px;
  .filter-icon {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    color: var(--main);
    text-transform: uppercase;
    line-height: 22px;
    font-size: 12px;
    font-weight: 600;
  }
  .canvas-header {
    @include flex(center, space-between);
    padding: 18px 20px;
    background-color: var(--bg-11);
    .icon-close-popup {
      font-size: 16px;
    }
  }
  .widget-facet {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.canvas-sidebar {
  .canvas-header {
    @include flex(center, space-between);
    padding: 0px 15px;
    background-color: var(--bg-11);
    min-height: 40px;
    .icon-close-popup {
      font-size: 16px;
    }
    .title {
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
  .canvas-body {
    padding: 15px;
  }
}

.canvas-compare {
  height: max-content !important;
  z-index: 5000;
  .close-popup {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    cursor: pointer;
  }
  .canvas-body {
    padding: 28px 0;
  }
  .canvas-wrapper {
    height: unset;
  }
}
.offcanvas-backdrop {
  z-index: 2000;
}
.tf-compare-list {
  display: flex;
  align-items: center;
  .tf-compare-head {
    flex: 0 0 22%;
    display: flex;
    align-items: center;
    .title {
      font-size: 28px;
      line-height: 34px;
    }
  }
  .tf-compare-offcanvas {
    display: flex;
    align-items: center;
    flex: 1 1 63%;
    overflow-x: auto;
    // &::-webkit-scrollbar {
    //     width: 0px;
    // }
    .tf-compare-item {
      flex: 0 0 16.666%;
      padding: 0 10px;
      position: relative;
      .icon {
        position: absolute;
        z-index: 5;
        top: 6px;
        right: 6px;
        width: 25px;
        height: 25px;
        background-color: var(--white);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8px;
      }
      img {
        width: 100%;
        // max-height: 197px;
        object-fit: cover;
      }
    }
  }
  .tf-compare-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 0 0 15%;
    a {
      height: 47px;
      width: 147px;
    }
    .tf-compapre-button-clear-all {
      margin-top: 24px;
      font-weight: 600;
      line-height: 19px;
      text-align: center;
      text-decoration: underline;
      text-underline-offset: 4px;
      cursor: pointer;
    }
  }
}

.modal-shopping-cart {
  // width: 100% !important;
  // max-width: 477px !important;
  // border: 0 !important;
  .modal-content {
    max-width: 477px !important;
    cursor: default !important;
  }
  .header {
    margin: 0 36px;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--line);
    height: 60px;
    .title {
      font-size: 20px;
    }
    .icon-close-popup {
      font-size: 16px;
      padding: 0 6px;
    }
  }
  .wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .tf-mini-cart-threshold {
    margin: 0 36px;
    padding: 18px 0 16px;
    border-bottom: 1px solid var(--line);
    .tf-progress-bar {
      margin-top: 14px;
      margin-bottom: 10px;
      height: 4px;
      // span::after {
      //     position: absolute;
      //     font-family: $fontIcon;
      //     content: "\e90e";
      //     right: 0;
      //     top: 50%;
      //     transform: translate(50%,-50%);
      //     width: 36px;
      //     height: 26px;
      //     border: 1px solid rgb(235, 0, 27);
      //     color: rgb(235, 0, 27);
      //     display: flex;
      //     align-items: center;
      //     justify-content: center;
      //     background-color: var(--white);
      // }
    }
    .tf-progress-msg {
      margin-top: 18px;
      font-size: 16px;
      line-height: 26px;
    }
  }
  .tf-mini-cart-wrap {
    flex: 1 1 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    .tf-mini-cart-main {
      flex: 1 1 auto;
      position: relative;
      .tf-mini-cart-sroll {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--line);
          border-radius: 6px;
        }
      }
    }
  }
  .tf-mini-cart-item {
    margin: 0 36px;
    padding: 20px 0;
    display: flex;
    gap: 24px;
    &:not(:last-child) {
      border-bottom: 1px solid var(--line);
    }
    .tf-mini-cart-image a {
      width: 80px;
      height: 110px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .tf-mini-cart-info {
      a {
        font-size: 16px;
        line-height: 16px;
        display: block;
      }
      .meta-variant {
        font-size: 12px;
        line-height: 19px;
        margin-top: 6px;
      }
      .price {
        line-height: 14px;
        margin-top: 6px;
      }
      .tf-mini-cart-btns {
        margin-top: 10px;
        display: flex;
        gap: 12px;
        align-items: center;
        .wg-quantity {
          width: 86px;
        }
        .tf-mini-cart-remove {
          font-size: 12px;
          line-height: 19px;
          color: var(--text);
          text-decoration: underline;
        }
      }
    }
  }
  .tf-minicart-recommendations {
    margin: 15px 36px;
    padding: 18px;
    box-shadow: 0 0.4rem 1.6rem #00000014;
    .tf-minicart-recommendations-heading {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tf-minicart-recommendations-title {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
      }
      .sw-dots {
        width: unset;
      }
    }
  }
  .tf-minicart-recommendations-item {
    display: flex;
    align-items: center;
    .tf-minicart-recommendations-item-image a {
      width: 80px;
      height: 110px;
      margin-right: 18px;
    }
    .tf-minicart-recommendations-item-infos {
      a {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        white-space: nowrap;
      }
      .price {
        margin-top: 4px;
        line-height: 14px;
        font-weight: 600;
      }
    }
    .tf-minicart-recommendations-item-quickview .quickview {
      margin-left: 15px;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--main);
      font-size: 16px;
      color: var(--white);
      @include transition3;
      &:hover {
        background-color: var(--primary);
      }
    }
  }
  .tf-mini-cart-tool {
    background-color: rgb(242, 242, 242);
    display: flex;
    justify-content: center;
    .tf-mini-cart-tool-btn {
      width: 69px;
      height: 43px;
      line-height: 43px;
      margin: 18px 10px;
      border-radius: 3px;
      cursor: pointer;
      color: var(--main);
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      @include transition3;
      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }
  .tf-mini-cart-bottom-wrap {
    padding: 20px 36px 30px;
    .tf-cart-totals-discounts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tf-cart-total {
        font-size: 20px;
      }
      .tf-totals-total-value {
        font-size: 20px;
        line-height: 32px;
        margin-left: 15px;
      }
    }
    .tf-cart-tax {
      margin-top: 10px;
      color: var(--text);
      a {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
    .tf-mini-cart-line {
      border: 1px solid var(--line);
      margin-top: 14px;
      margin-bottom: 14px;
    }
    .tf-cart-checkbox {
      margin-top: 10px;
    }
    .tf-mini-cart-view-checkout {
      margin-top: 20px;
      display: flex;
      gap: 14px;
    }
  }
}

.tf-cart-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  .tf-checkbox-wrapp {
    min-width: 1.6rem;
    place-items: center;
    position: relative;
    overflow: hidden;
    display: flex;
    input {
      cursor: pointer;
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50% !important;
      transition: 0.2s ease-in-out;
      background-color: var(--white);
      opacity: 0;
      &:checked + div {
        background-color: var(--primary);
        i {
          transform: scale(1);
        }
      }
    }
    div {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      transition: 0.25s ease-in-out;
      z-index: 5;
      border: 1px solid var(--line);
      border-radius: 50%;
      background-color: var(--white);
      color: var(--white);
      pointer-events: none;
      i {
        font-size: 6px;
        transform: scale(0);
      }
    }
  }
  label {
    font-weight: 400;
    cursor: pointer;
    a {
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  }
  .wrap-content {
    display: none;
  }
  &.check {
    .wrap-content {
      display: block;
    }
  }
}

.tf-mini-cart-tool-openable {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: transform 0.25s ease-in-out;
  z-index: 70;
  &.open {
    transform: translateY(0);
    > .overplay {
      opacity: 1;
      visibility: visible;
    }
  }
  > .overplay {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 200vh;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    @include transition3;
    // z-index: 60;
  }
  .tf-mini-cart-tool-close {
    cursor: pointer;
  }
  .tf-btn {
    height: 47px;
  }
  .tf-mini-cart-tool-content {
    position: relative;
    z-index: 80;
    padding: 32px 36px;
    background-color: rgb(242, 242, 242);
    .tf-mini-cart-tool-text {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 28px;
      .icon {
        width: 33px;
        height: 33px;
        border: 1px solid var(--main);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--main);
      }
    }
    .tf-cart-tool-btns {
      margin-top: 32px;
      display: flex;
      gap: 30px;
      align-items: center;

      .tf-mini-cart-tool-primary {
        text-decoration: underline;
        text-underline-offset: 8px;
      }
    }
  }
  &.add-gift {
    .tf-mini-cart-tool-text {
      gap: 20px;
      align-items: flex-start;
      .icon {
        width: 60px;
        height: 60px;
        font-size: 24px;
        background-color: var(--white);
        color: var(--main);
        border: 0;
      }
    }
    .tf-gift-wrap-infos {
      p {
        font-size: 20px;
        line-height: 32px;
      }
    }
  }
  &.estimate-shipping {
    .tf-mini-cart-tool-text {
      margin-bottom: 20px;
    }
    select {
      height: 49px;
    }
    .field {
      margin-bottom: 15px;
      p {
        margin-bottom: 8px;
      }
    }
  }
}

.canvas-search {
  width: 100% !important;
  max-width: 463px;
  padding-top: 55px;
  border: 0 !important;
  .tf-search-head {
    padding: 0 22px;
    border-bottom: 1px solid var(--line);
    margin-bottom: 22px;
    box-shadow: unset;
    .title {
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 19px;
      @include flex(center, space-between);
    }
    .close {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
    }
    .tf-search-sticky {
      margin-bottom: 30px;
    }
  }
  .tf-search-content {
    padding: 0 22px 16px 22px;
  }
  .tf-search-content-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .tf-col-quicklink {
    margin-bottom: 32px;
    .tf-search-content-title {
      margin-bottom: 14px;
    }
    .tf-quicklink-item a {
      padding: 4px 0;
      line-height: 22.4px;
    }
  }
  .tf-search-hidden-inner {
    padding-top: 5px;
  }
  .tf-loop-item {
    display: flex;
    gap: 19px;
    align-items: flex-start;
    &:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 1px solid var(--line);
      margin-bottom: 16px;
    }
    .image {
      width: 68px;
      max-height: 95px;
    }
    .tf-product-info-price {
      > div {
        font-size: 14px;
      }
    }
  }
}

.tf-mini-search-frm {
  position: relative;
  input {
    height: 42px;
    padding: 12px 20px 12px 44px;
    font-size: 16px;
    line-height: 26px;
    color: var(--main);
    &::placeholder {
      font-size: 16px;
      line-height: 26px;
      color: var(--main);
    }
  }
  button {
    position: absolute;
    left: 14px;
    top: 0;
    font-size: 16px;
    margin: 13px 0;
    background-color: transparent;
    border: 0;
    outline: none;
  }
}

.form-sign-in {
  .modal-dialog {
    max-width: 640px;
    .modal-content {
      border: 0;
      padding: 37px 35px;
      border-radius: 3px;
    }
  }
  .header {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .demo-title {
      font-size: 28px;
      line-height: 33.6px;
    }
    .icon-close-popup {
      padding: 0 6px;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .tf-login-form form {
    > div {
      margin-top: 15px;
    }
    .btn-link {
      margin: 10px 0;
      text-decoration: underline !important;
      text-underline-offset: 3px;
      color: var(--text);
      .icon {
        font-size: 8px;
      }
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      .btn-link {
        color: var(--main);
      }
    }
  }
}

.toolbar-shop-mobile {
  max-width: min(90%, 430px) !important;
  .mb-canvas-content {
    max-width: 100%;
    padding-top: 70px;
    padding-left: 0;
  }
  .mb-body {
    padding: 0 20px 0 21px;
  }
  ul.nav-ul-mb > li {
    padding: 0 !important;
    border: 0 !important;
  }
  .tf-category-link {
    gap: 16px;
    min-height: 50px !important;
    padding: 4px 0 6px;
    position: relative;
    .image {
      width: 34px;
      height: 34px;
      position: relative;
      &::before {
        position: absolute;
        z-index: 1;
        content: "";
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
        width: calc(100% + 6px);
        height: calc(100% + 6px);
        border: solid 1px var(--line);
        margin: auto;
        pointer-events: none;
        border-radius: 50%;
      }
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
        border-radius: 50%;
      }
    }
    > span:nth-child(2) {
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
    }
    &::after {
      position: absolute;
      bottom: 0;
      content: "";
      height: 1px;
      width: calc(100% - 53px);
      right: 0;
      left: 53px;
      background-color: rgba(0, 0, 0, 0.12);
    }
    .btn-open-sub {
      width: 40px;
      height: 40px;
      border-left: 1px solid var(--line);
    }
    // &[aria-expanded="true"],
    &.current {
      &::after {
        display: none;
      }
    }
    &.has-children:not(.collapsed) {
      &::after {
        display: none;
      }
    }
  }
  .sub-nav-menu {
    margin: 0px 0 17px 50px !important;
    padding: 0 !important;
    .tf-category-link {
      padding: 4px 0 4px 15px;
      margin-bottom: 1px;
      min-height: 30px !important;
      &::after {
        display: none;
      }
    }
  }
  .sub-menu-level-2 {
    margin-left: 65px !important;
  }
  .mb-bottom {
    min-height: 50px;
    clear: both;
    padding: 15px 26px;
    background-color: rgba(0, 0, 0, 0.05);
    a {
      line-height: 13px;
    }
  }
  .list-cate {
    position: relative;
    &.show::after {
      position: absolute;
      bottom: -17px;
      content: "";
      height: 1px;
      width: calc(100% - 53px);
      right: 0;
      left: 53px;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}

#sidebarmobile {
  max-height: 100vh;
  overflow-y: auto;
  .tf-section-sidebar {
  }
}

.modal-newleter {
  .modal-content {
    .modal-top {
      position: relative;
      border-radius: 10px 10px 0px 0px;
      overflow: hidden;
      .icon {
        position: absolute;
        cursor: pointer;
        top: 13px;
        right: 13px;
        width: 30px;
        height: 30px;
        @include flex(center, center);
        border-radius: 999px;
        background-color: var(--white);
        color: var(--main);
        font-size: 12px;
      }
    }
    .modal-bottom {
      border-radius: 0px 0px 10px 10px;
      background-color: var(--white);
      padding: 20px;
      h6 {
        margin-top: 17px;
      }
      .tf-btn {
        margin-top: 25px;
      }
    }
    .form-newsletter {
      margin-top: 26px;
    }
  }
}
.canvas-sidebar-blog {
  .canvas-header {
    background-color: var(--white);
    padding: 14px 20px;
    .title {
      font-size: 16px;
      line-height: 19.2px;
      font-weight: 400;
    }
  }
  .canvas-body {
    padding: 20px;
  }
}
