.testimonial-item {
    .icon {
        margin-bottom: 16px;
    }
    .rating {
        margin-bottom: 16px;
    }
    .text {
        font-size: 16px;
        line-height: 28.8px;
        margin-bottom: 11px;
    }
    .divider {
        margin-bottom: 10px;
        max-width: 147px;
        margin-left: auto;
        margin-right: auto;
        height: 1px;
        border-top: 1px dashed var(--line);
    }
    .name {
        font-weight: 600;
    }
    .metas {
        color: var(--text);
        span {
            font-weight: 600;
        }
    }
    .heading {
        font-size: 18px;
        font-weight: 600;
        line-height: 29px;
    }
    .product {
        border-top: 1px solid var(--line);
        padding-top: 23px;
        position: relative;
        display: flex;
        align-items: center;
        .image {
            width: 100%;
            max-width: 64px;
        }
        .content-wrap {
            padding-left: 24px;
            padding-right: 20px;
            .product-title {
                margin-bottom: 6px;
                a {
                    &:hover {
                        color: var(--primary);
                    }
                }
            }
            .price {
                font-weight: 600;
                color: var(--main);
            }
        }
        > a {
            position: absolute;
            right: 0;
            width: 38px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid var(--main);
            opacity: 0;
            visibility: hidden;
            font-size: 9px;
            &:hover {
                background-color: var(--main);
                color: var(--white);
            }
            &.text_blue-1 {
                border-color: #1c355e;
                &:hover {
                    background-color: #1c355e;
                    i {
                        color: var(--white);
                    }
                }
            }
        }
    }
    &:hover {
        .product {
            > a {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.style-column {
        padding: 34px 20px 20px;
        border: 1px solid var(--line);
        border-radius: 10px;
        .rating {
            margin-bottom: 14px;
        }
        .heading {
            margin-bottom: 8px;
        }
        .text {
            margin-bottom: 20px;
        }
        .author {
            margin-bottom: 30px;
        }
        &.style-2{
            background-color: var(--white);
            border: 0;
            border-radius: 0;
            padding: 20px;
            .text{
                font-size: 16px;
                line-height: 25.6px;
                margin-bottom: 10px;
            }
            .author{
                margin-bottom: 0px;
                svg{
                    color: var(--bg-5);
                }
            }
        }
    }
    &.style-row {
        display: flex;
        gap: 34px;
        .image {
            width: 244px;
            flex-shrink: 0;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .product {
            // .rating {
            //     margin-bottom: 5px;
            //     i {
            //         color: var(--main);
            //         font-size: 12px;
            //     }
            // }
            border: none;
            .img-thumb{
                max-width: 64px;
            }
        }
        .rating{
            margin-bottom: 10px;
        }
        .text{
            font-size: 16px;
            line-height: 25.6px;
        }
    }
    &.style-box {
        padding: 28px 24px 40px 34px;
        background-color: var(--white);
        border-radius: 10px;
        .author {
            display: flex;
            flex-wrap: wrap;
            gap: 14px;
            align-items: center;
            margin-bottom: 19px;
            .name {
                font-weight: 700;
            }
            .metas {
                padding: 6px 16px;
                display: flex;
                align-items: center;
                gap: 9px;
                background-color: var(--main);
                color: var(--white);
                font-size: 12px;
                line-height: 19px;
                i {
                    font-size: 10px;
                }
            }
        }
        .rating {
            margin-bottom: 19px;
        }
        .text {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
        }
    }
    &.style-box-1 {
        padding: 37px 33px 34px;
        border-radius: 10px;
        background-color: var(--white);
        min-height: 444px;
        display: flex;
        flex-direction: column;
        .rating {
            margin-bottom: 18px;
            i {
                font-size: 13px;
                color: #00b67a;
            }
        }
        .text {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: var(--main);
            flex-grow: 1;
        }
        .metas {
            display: flex;
            align-items: center;
            gap: 5px;
            i {
                font-size: 16px;
                color: #00b67a;
            }
        }
    }
    &.lg {
        .icon {
            font-size: 28px;
        }
        .text {
            font-size: 16px;
            line-height: 25.6px;
            margin-bottom: 16px;
        }
        .divider{
            margin-left: 0;
        }
    }
    .box-author{
        display: flex;
        gap: 16px;
        .box-img{
            width: 70px;
            height: 74px;
            border-radius: 10px;
            overflow: hidden;
            flex-shrink: 0;
        }
        .content{
            // margin-top: 5px;
        }
    }
    &.lg-2{
        .box-author{
            margin-bottom: 16px;
        }
    }
}

.rating {
    display: flex;
    align-items: center;
    gap: 7px;
    i {
        font-size: 16px;
        color: var(--bg-5);
    }
    &.color-black {
        i {
            color: var(--main);
        }
    }
}

.flat-wrap-tes-text{
    margin-bottom: 25px;
    .testimonial-ver-text{
        padding: 0 50px;
    }
  
}
.testimonial-ver-text{
    display: flex;
    flex-direction: column;
    text-align: center;
    .heading{
        letter-spacing: 0.8px;
        font-size: 16px;
        line-height: 25.6px;
        font-weight: 700;
        color: var(--main);
        margin-bottom: 20px;
    }
    .content{
        font-size: 16px;
        line-height: 25.6px;
    }
}
.thumb-tes-brands{
    .swiper-slide{
        width: auto !important;
    }
    .swiper-wrapper{
        justify-content: center;
    }
    .thumb-tes-item{
        padding-left: 10px;
        padding-right: 10px;
        opacity: 0.3;
        @include transition3;
        img{
            max-width: 100px;
            cursor: pointer;
        }
        &:hover{
            opacity: 1;
        }
    }
    .swiper-slide-thumb-active{
        .thumb-tes-item{
            opacity: 1;
        }  
    }
}