.widget-tabs {
    .widget-menu-tab {
        .item-title {
            &:not(.default) {
                font-size: 18px;
                font-weight: 600;
                line-height: 30px;
                cursor: pointer;
                position: relative;
                &::after {
                    position: absolute;
                    content: "";
                    background-color: var(--main);
                    @include transition3;
                }
            }
        }
    }
    .widget-content-tab {
        position: relative;
        overflow: hidden;
        .widget-content-inner {
            padding: 35px;
            display: block;
            pointer-events: none;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            right: 0;
            -webkit-transform: translateY(30px);
            -ms-transform: translateY(30px);
            transform: translateY(30px);
            transition-timing-function: ease-in;
            transition-duration: 0.2s;
            &.active {
                pointer-events: auto;
                opacity: 1;
                visibility: visible;
                position: relative;
                z-index: 2;
                -webkit-transform: none;
                -ms-transform: none;
                transform: none;
                transition-timing-function: ease-out;
                transition-duration: 0.3s;
                transition-delay: 0.3s;
            }
        }
    }
    &.style-has-border {
        border: 1px solid var(--line);
        .widget-menu-tab {
            display: flex;
            gap: 10px 50px;
            margin: 0 38px;
            border-bottom: 1px solid var(--line);
            overflow-x: auto;
            .item-title {
                padding: 15px 0;
                min-width: max-content;
                &::after {
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                }
                &.active {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
    }
    &.style-two-col {
        display: grid;
        grid-template-columns: 3fr 9fr;
        gap: 30px;
        .widget-menu-tab {
            display: flex;
            flex-direction: column;
            gap: 10px 50px;
            border-left: 2px solid var(--line);
            height: max-content;
            .item-title {
                padding: 15px 38px;
                &::after {
                    top: 0;
                    left: -2px;
                    width: 2px;
                    height: 0;
                }
                &.active {
                    &::after {
                        height: 100%;
                    }
                }
            }
        }
        .widget-content-tab {
            border: 1px solid var(--line);
            height: fit-content;
        }
    }
}

.widget-tab-2 {
    @include flex(center, space-between);
    margin-bottom: 30px;
    gap: 15px;
    .nav-tab-item {
        width: 100%;
        a {
            display: flex;
            justify-content: center;
            width: 100%;
            color: var(--main);
            font-size: 20px;
            font-weight: 400;
            white-space: nowrap;
            padding-bottom: 10px;
            padding-inline-start: 15px;
            padding-inline-end: 15px;
            border-bottom: 2px solid rgb(238, 238, 238);
            transition: border-bottom-color 0.3s;
            &.active {
                border-bottom-color: var(--main);
            }
        }
    }
}

.widget-tab-3 {
    margin-bottom: 30px;
    gap: 70px;
    margin-bottom: 70px;
    overflow-x: auto;
    .nav-tab-item {
        a {
            display: flex;
            justify-content: center;
            width: 100%;
            font-size: 32px;
            line-height: 38.4px;
            font-weight: 400;
            white-space: nowrap;
            padding-bottom: 12px;
            border-bottom: 2px solid transparent;
            @include transition3;
            color: var(--text);
            &.active{
                font-weight: 500;
                border-bottom-color: var(--main);
            }
            &:hover,
            &.active {
                color: var(--main);
            }
        }
    }
    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;

    }
    &.style-3{
        gap: 30px;
        .nav-tab-item{
            a{
                font-weight: 500;
                &.active{
                    font-weight: 600;
                }
            }
        }
    }
    
}

.widget-tab-4 {
    border-radius: 10px;
    border: 1px solid var(--main);
    gap: 20px;
    padding: 20px 15px;
    display: flex;
    .nav-tab-link {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 0px;
        min-height: 30px;
        @include transition3;
        cursor: pointer;
        .icon {
            font-size: 10px;
            font-weight: 500;
            display: none;
        }
        span {
            font-size: 15px;
            line-height: 18px;
            font-weight: 500;
            display: flex;
            gap: 5px;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 100%;
            overflow: hidden;
        }
        .count {
            font-weight: 400;
            margin-top: -2px;
        }
        &.active {
            .icon {
                display: block;
            }
        }
        
    }
}
.widget-tab-5{
    display: flex;
    align-items: center;
    gap: 30px;
    overflow-x: auto;
    .nav-tab-item {
        width: 100%;
        a {
            display: flex;
            justify-content: center;
            width: 100%;
            color: var(--text);
            font-size: 14px;
            line-height: 16.8px;
            font-weight: 500;
            white-space: nowrap;
            padding: 6px 0px;
            border: 2px solid transparent;
            &.active {
                padding: 6px 20px;
                border-radius: 60px;
                border-color: var(--primary);
                color: var(--primary);
            }
            &:hover{
                color: var(--primary);
            }
        }
    }
}

.flat-animate-tab {
    overflow: hidden;
    .tab-content {
        position: relative;
    }
    .tab-pane {
        display: block;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        -webkit-transform: translateY(30px);
        -ms-transform: translateY(30px);
        transform: translateY(30px);
        transition-timing-function: ease-in;
        transition-duration: 0.2s;
        &.active {
            pointer-events: auto;
            opacity: 1;
            visibility: visible;
            position: relative;
            z-index: 2;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;

            transition-timing-function: ease-out;
            transition-duration: 0.3s;
            transition-delay: 0.3s;
        }
    }
}
