.wrap-slider {
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .box-content {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .heading,
    h1 {
      margin-bottom: 18px;
      &.mb_38 {
        margin-bottom: 38px;
      }
    }
    p {
      margin-bottom: 48px;
      font-size: 20px;
      line-height: 24px;
      &.mb_15 {
        margin-bottom: 15px;
      }
      &.sale_off {
        background-color: #ff5c39;
        padding: 5px 20px;
        width: max-content;
        margin-left: auto;
        margin-right: auto;
        font-size: 14px;
      }
    }
  }
}
.tf-slideshow {
  overflow: hidden;
  .wrap-pagination {
    position: absolute;
    z-index: 10;
    bottom: 57px;
    left: 0;
    right: 0;
  }
  .sw-absolute-3 {
    right: 3%;
    left: unset;
    width: auto;
    .sw-dots {
      flex-direction: column;
    }
  }
  .card-box {
    padding: 64px 48px 70px;
    border-radius: 10px;
    max-width: 550px;

    .heading {
      margin-bottom: 38px;
    }
  }
  .subheading {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 22.4px;
  }
}
.slider-skincare {
  .sw-absolute-3 {
    top: 50%;
    transform: translateY(-50%);
    bottom: unset;
  }
}
.slider-effect-fade {
  .swiper-slide {
    .fade-item {
      transform: translateY(100px);
      opacity: 0;
      visibility: hidden;
      @include transition3;
      &.fade-box {
        transition-delay: 0.4s;
      }
      &.fade-item-1 {
        transition-delay: 0.5s;
      }
      &.fade-item-2 {
        transition-delay: 0.6s;
      }
      &.fade-item-3 {
        transition-delay: 0.7s;
      }
      &.fade-item-4 {
        transition-delay: 0.8s;
      }
    }
    &.swiper-slide-active {
      .fade-item {
        transform: translateY(0);

        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.slider-giftcard {
  .wrap-slider {
    height: 350px;
    .heading {
      margin-bottom: 18px;
      font-weight: 500;
    }
  }
}
.hover-sw-nav {
  // margin-bottom: 10px;
  .sw-dots {
    margin-top: 15px;
    position: relative;
    z-index: 10;
    display: none;
    transform: unset !important;
    bottom: unset;
  }
}

.slider-effect {
  position: relative;
  &.wrap-slider {
    height: auto !important;
  }
  .content-left {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    background-color: rgb(238, 241, 224);
    .box-content {
      position: unset;
      transform: unset;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .img-slider {
    width: 50%;
    margin-left: auto;
  }
}
.swiper-slide {
  .box-content {
    opacity: 0;
  }
  &.swiper-slide-active {
    .box-content {
      opacity: 1;
    }
  }
}
.slideshow-effect-zoom {
  padding-top: 74px;
  padding-bottom: 100px;
  .content-left {
    padding: 20px;
    padding-left: 0;
    .desc {
      margin-top: 18px;
      font-size: 20px;
      line-height: 32px;
      color: rgb(84 84 84);
    }
    .tf-btn {
      margin-top: 48px;
    }
  }
  .wrap-content {
    display: flex;
    align-items: center;
    .content-left,
    .content-right {
      width: 50%;
    }
  }
}
.grid-img-group {
  position: relative;
  .item-1 {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: 1;
    width: 51%;
    @include transition3;
    &:hover {
      z-index: 2;
    }
  }
  .item-2 {
    margin-left: auto;
    width: 84%;
    @include transition3;
    &:hover {
      z-index: 2;
      position: relative;
    }
  }
  .img-style {
    border-radius: 10px;
  }
  &.style-ter-1 {
    padding-bottom: 66px;
    .item-1 {
      width: 53%;
      align-items: flex-end;
    }
    .item-2 {
      width: 79%;
    }
  }
}

.tf-slideshow {
  .box-content {
    .subheading {
      margin-bottom: 14px;
    }
  }
  .banner-wrapper {
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .box-content {
      position: absolute;
      left: 15px;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    .description {
      margin-top: 20px;
      font-size: 16px;
      line-height: 19.2px;
    }
    .tf-btn {
      margin-top: 30px;
    }
  }
  &.about-us-page {
    .text {
      font-size: 68px;
      line-height: 81.6px;
    }
    img {
      max-height: 860px;
    }
  }
  .text-in-right {
    .box-content {
      left: unset;
    }
  }
}
.slider-video {
  .tf-btn {
    margin-top: 28px;
  }
}
.slider-radius {
  padding: 0 40px;
  .tf-sw-slideshow {
    border-radius: 60px;
    .heading {
      margin-bottom: 38px;
    }
    .subheading {
      font-size: 14px;
      line-height: 22.4px;
    }
  }
  .row-end {
    .box-content {
      left: unset;
    }
  }
}

.autoplay-linear {
  .swiper-wrapper {
    transition-timing-function: linear;
  }
}
.slideshow-men {
  // overflow: unset;
  // z-index: 900;

  .tf-sw-slideshow {
    padding-top: 60px;
    margin-top: -60px;
  }
  .wrap-slider {
    position: relative;
  }
  .lookbook-1 {
    .lookbook-item {
      position: absolute;
    }
    .item-1 {
      top: 8%;
      left: 60%;
    }
    .item-2 {
      top: 35%;
      left: 50%;
    }
  }
  .lookbook-2 {
    .lookbook-item {
      position: absolute;
    }
    .item-1 {
      top: 36%;
      left: 50%;
    }
  }
  .wrap-pagination {
    bottom: 45px;
  }
}
.slideshow-lookbook {
  .wrap-slider {
    position: relative;
  }
  .lookbook-1 {
    .lookbook-item {
      position: absolute;
    }
    .item-1 {
      top: 39%;
      left: 35%;
    }
    .item-2 {
      top: 59%;
      left: 60%;
    }
    .item-3 {
      top: 26%;
      left: 74%;
    }
  }
  .wrap-pagination {
    bottom: 45px;
  }
}

.banner-collection-men-wrap {
  position: relative;
  .box-content {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
  .card-box {
    background-color: var(--white);
    border-radius: 10px;
    padding: 20px 15px;
    .subheading {
      font-size: 14px;
      line-height: 16.8px;
      font-weight: 700;
    }
    .heading {
      font-weight: 400;
      font-size: 24px;
      line-height: 28.8px;
      margin-top: 16px;
    }
    .text {
      margin-top: 20px;
      font-size: 16px;
      line-height: 25.6px;
      color: var(--text);
    }
    .tf-btn {
      margin-top: 20px;
    }
  }
  .img-wrap {
    height: 450px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.banner-parallax {
  height: 350px;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-skateboard {
  .wrap-slider .box-content {
    top: unset;
    transform: unset;
    bottom: 10%;
  }
}
.tf-slideshow {
  .card-box-2 {
    max-width: 400px;
    .title {
      margin-bottom: 12px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19.2px;
    }
    .price {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 20px;
      display: inline-block;
    }
  }
}

.flat-testimonial-bg {
  padding: 40px 0px 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flat-testimonial-bg-1 {
  background-image: url(/images/slider/image_bg_testimonials.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.height-auto {
  height: auto;
  > .h-100 {
    height: 100% !important;
  }
}
.slideshow-tee {
  .wrap-slider {
    .box-content {
      .heading {
        font-size: 34px;
        line-height: 40.8px;
      }
      p {
        font-size: 34px;
        line-height: 40.8px;
      }
    }
  }
  &.tf-slideshow {
    .wrap-slider {
      height: 400px;
    }
  }
}
.flat-testimonial-bg-v2 {
  padding: 50px 0px 40px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  .wrap-content-left {
    .rating {
      margin-bottom: 10px;
    }
  }
  .wrap-content-right {
    margin-top: 15px;
    .box-sw-navigation {
      margin-top: 30px;
    }
  }
}
.slider-sock {
  .img-mb {
    display: none;
  }
}
.slider-accessories {
  position: relative;
  .wrap-slider {
    .box-content {
      top: 10%;
      transform: unset;
    }
  }
  .wrap-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    z-index: 50;
  }
  .nav-sw {
    position: absolute;
  }
  .nav-next-slider {
    left: 6%;
  }
  .nav-prev-slider {
    right: 6%;
  }
}
