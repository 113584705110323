.tf-zoom-main {
    position: sticky;
    top: 30px;
    z-index: 50;
    .drift-zoom-pane {
        top: 0;
        left: 0;
        height: 520px;
        max-width: 520px;
        width: 100%;
        background: #fff;
        -webkit-transform: translate3d(0, 0, 0);
        box-shadow: 0 1px 5px #7f7f7f05, 0 5px 18px #7f7f7f33;
        z-index: 3;
    }
}

.drift-bounding-box.drift-open {
    background: #ffffff40;
    box-shadow: 0 0 0 1px #0006 inset;
    z-index: 5000;
}

.drift-zoom-pane {
    z-index: 5000;
}

.section-image-zoom {
    .other-image-zoom {
        @include transition3;
    }
    &.zoom-active .other-image-zoom {
        opacity: 0.3;
    }
}

.pswp__bg {
    background: var(--white);
}

.pswp__icn {
    fill: rgb(34, 34, 34);
    color: var(--white);
    .pswp__icn-shadow {
        stroke: var(--white);
        stroke-width: 1px;
        fill: none;
    }
}

.pswp__counter {
    color: #222;
    text-shadow: 1px 1px 3px #ffffff;
}

.tf-model-viewer {
    width: 100%;
    height: 100%;
    position: relative;
    model-viewer {
        display: block;
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        &.disabled {
            pointer-events: none;
        }
    }
    &.active {
        model-viewer {
            pointer-events: all;
        }
        .wrap-btn-viewer {
            display: none;
        }
    }
}