// ------- box icon ---------

.box-icon {
    @include flex(center, center);
    @include transition4;
    cursor: pointer;
    &.w_28 {
        width: 28px;
        height: 28px;
    }

    &.w_34 {
        width: 34px;
        height: 34px;
    }
    &.w_46 {
        width: 46px;
        height: 46px;
    }
    &.w_50 {
        width: 50px;
        height: 50px;
    }

    &.round {
        border-radius: 50% !important;
    }
}
.tf-social-icon {
    --facebook-cl: rgb(59, 89, 152);
    --twitter-cl: rgb(85, 85, 85);
    --instagram-cl: linear-gradient(#8a3ab9, #e95950, #fccc63);
    --threads-cl: rgb(224, 53, 102);
    --youtube-cl: rgb(205, 32, 31);
    --tiktok-cl: linear-gradient(#25f4ee, #000, #fe2c55);
    --tiktok-cl2: rgb(254, 44, 85);
    --pinterest-cl: rgb(203, 32, 39);
    --tumblr-cl: rgb(55, 69, 92);
    --vimeo-cl: rgb(26, 183, 234);
    --snapchat-cl: rgb(255, 221, 0);
    --whatsapp-cl: rgb(0, 230, 118);
    --linked_in-cl: rgb(23, 106, 255);
    --wechat-cl: rgb(26, 173, 24);
    --reddit-cl: rgb(255, 69, 0);
    --line-cl: rgb(0, 195, 77);
    --spotify-cl: rgb(30, 125, 96);
    .social-facebook:hover {
        background: var(--facebook-cl);
        color: var(--white);
    }
    .social-twiter:hover {
        background: var(--twitter-cl);
        color: var(--white);
    }
    .social-instagram:hover {
        background: var(--instagram-cl);
        color: var(--white);
    }
    .social-tiktok:hover {
        background: var(--tiktok-cl);
        color: var(--white);
    }
    .social-pinterest:hover {
        background: var(--pinterest-cl);
        color: var(--white);
    }
    &.style-white {
        li {
            a {
                border-color: var(--white) ;
                color: var(--white) ;
                // &:hover {
                //     color: var(--primary) !important;
                // }
            }
        }
    }
    .social-line {
        border: 1px solid var(--main);
        &.social-facebook {
            &:hover {
                background: transparent;
                color: var(--facebook-cl);
                border-color: var(--facebook-cl);
            }
        }
        &.social-twiter {
            &:hover {
                background: transparent;
                color: var(--twitter-cl);
                border-color: var(--twitter-cl);
            }
        }
        &.social-instagram {
            &:hover {
                background: transparent;
                color: var(--threads-cl);
                border-color: var(--threads-cl);
            }
        }
        &.social-tiktok {
            &:hover {
                background: transparent;
                color: var(--tiktok-cl2);
                border-color: var(--tiktok-cl2);
            }
        }
        &.social-pinterest {
            &:hover {
                background: transparent;
                color: var(--pinterest-cl);
                border-color: var(--pinterest-cl);
            }
        }
    }
  
    &.style-default {
        li {
            display: inline-flex;
            a {
                border: 0 !important;
                &:hover {
                    background: unset !important;
                }
            }
            .social-facebook{
                &:hover{
                    color: var(--facebook-cl);
                }
            }
            .social-twiter {
                &:hover {
                    color: var(--twitter-cl);
                }
            }
            .social-instagram {
                &:hover {
                    color: var(--threads-cl);
                }
            }
            .social-tiktok {
                &:hover {
                    color: var(--tiktok-cl2);
                }
            }
            .social-pinterest {
                &:hover {
                    color: var(--pinterest-cl);
                }
            }
        }
    }
}

.tf-icon-box {
    .icon {
        margin-bottom: 34px;
        i {
            font-size: 30px;
            color: #c5c5c5;
        }
    }
    .content {
        .title {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 10px;
        }
    }
    &.style-border-line {
        border: 1px solid #ececec;
        padding: 26px 20px;
        border-radius: 10px;
        .icon {
            i {
                color: var(--main);
            }
        }
    }
    &.style-row {
        display: flex;
        gap: 22px;
        align-items: center;
        .icon {
            width: 60px;
            height: 60px;
            border: 1px solid #ececec;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            i {
                color: var(--main);
            }
        }
    }
}

.swiper-slide{
    .tf-icon-box {
        width: 100%;
        height: 100%;
    }
}

