.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  background: rgb(235, 235, 235);
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}

.noUi-handle {
  position: relative;
  z-index: 1;
}

.noUi-stacking .noUi-handle {
  z-index: 10;
}

.noUi-state-tap .noUi-origin {
  -webkit-transition:
    left 0.3s,
    top 0.3s;
  transition:
    left 0.3s,
    top 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.noUi-horizontal {
  height: 3px;
}

.noUi-horizontal .noUi-handle {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--primary);
  border: 2px solid transparent;
  cursor: pointer;
}

.caption {
  margin-bottom: 10px;
}

/* Styling; */
.noUi-background {
  background: rgb(235, 235, 235);
}

.noUi-connect {
  background: var(--primary);
  -webkit-transition: background 450ms;
  transition: background 450ms;
}

.noUi-origin {
  border-radius: 0px;
}

.noUi-target {
  width: 100%;
  padding-right: 15px;
}

// .noUi-horizontal .noUi-handle.noUi-handle-lower {
//   left: -22px;
// }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

/* Disabled state; */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.slider-labels .caption {
  font-weight: 500;
  font-size: 16px;
}
