
@mixin flex($alignItem, $justifyContent) {
    display: flex;
    align-items: $alignItem;
    justify-content: $justifyContent;
}
@mixin grid($loop, $num) {
    display: grid;
    grid-template-columns: repeat($loop, $num);
}
@mixin transformScale {
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
}


@mixin topcenter() {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
@mixin center() {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin wh-full() {
    width: 100%;
    height: 100%;
}



// --------------------------

@mixin  transition3 (){
    -webkit-transition: all 0.3s ease;
    -moz-transition   : all 0.3s ease;
    -ms-transition    : all 0.3s ease;
    -o-transition     : all 0.3s ease;
    transition        : all 0.3s ease;
}
@mixin  transition4 (){
    -webkit-transition: all 0.4s ease;
    -moz-transition   : all 0.4s ease;
    -ms-transition    : all 0.4s ease;
    -o-transition     : all 0.4s ease;
    transition        : all 0.4s ease;
}

@mixin  transition5 (){
    -webkit-transition: all 0.5s ease;
    -moz-transition   : all 0.5s ease;
    -ms-transition    : all 0.5s ease;
    -o-transition     : all 0.5s ease;
    transition        : all 0.5s ease;
}

@mixin  transition6 (){
    -webkit-transition: all 0.6s ease;
    -moz-transition   : all 0.6s ease;
    -ms-transition    : all 0.6s ease;
    -o-transition     : all 0.6s ease;
    transition        : all 0.6s ease;
}

@mixin  transitionY (){
    -webkit-transform: translateY(-50%);
    -ms-transform    : translateY(-50%);
    -o-transform     : translateY(-50%);
    transform        : translateY(-50%);
}

@mixin  transitionY3 (){
    -webkit-transform : translateY(30px);
    -ms-transform     : translateY(30px);
    -o-transform      : translateY(30px);
    transform         : translateY(30px);
}

@mixin  transitionY0 (){
    -webkit-transform : translateY(0);
    -ms-transform     : translateY(0);
    -o-transform      : translateY(0);
    transform         : translateY(0);
}

@mixin absolute {
    top     : 0;
    right   : 0;
    left    : 0;
    bottom  : 0;
    position: absolute;
}






@mixin icon {
    width   : 50px;
    height  : 50px;
    top     : 0;
    right   : 0;
    left    : 0;
    bottom  : 0;
    position: absolute;
}

@mixin boxshadow {
    content: "";
    border: 1px solid $outline;
    position: absolute;
    right: -11px;
    bottom: -11px;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@mixin imgover {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 53.85%, #000000 100%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 8px;
}